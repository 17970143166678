import React, { Component } from "react";
import ReactExport from "react-data-export";
import Button from '@material-ui/core/Button';

import moment from 'moment'
import 'moment/locale/es';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class DownloadExcel extends Component {
    constructor(props) {
        super(props);
        this.state = { done: false, extraQuestions: [] };
    }
    componentDidMount() {
        this.formatData()
    }
    formatData = () => {
        let users = this.props.userData
        let data = []
        let extraQuestions = []
        users.forEach((user, index) => {
            if (user.extraQuestions) {
                user.extraQuestions.forEach(question => {
                    if (!extraQuestions.includes(question.question)) {
                        extraQuestions.push(question.question)
                    }
                })
            }
        })
        users.forEach((user, index) => {
            let links = ""
            if (user.links) {
                user.links.forEach((link, index) => {
                    let carriageReturn = (index === user.links.length - 1) ? "" : "\n"
                    links = links + link + carriageReturn
                })
            }
            data.push({
                nombre: user.name, fecha: moment(user.appliedTimestamp, "x").locale('es').format("DD MMM YYYY HH:mm"),
                phoneNumber: user.phoneNumber, email: user.email, residence: user.residence,
                cv: user.cv, selfDescription: user.selfDescription, reasonForApplication: user.reasonForApplication,
                selfDescription: user.selfDescription, desiredSalary: user.desiredSalary, links: links
            })
            if (user.extraQuestions) {
                user.extraQuestions.forEach(question => {
                    data[index][question.question] = question.answer
                })
            }
        })
        this.setState({ excelData: data, extraQuestions, done: true })

    }
    render() {
        if (!this.state.done)
            return null
        else
            return (
                <ExcelFile filename={`candidatos - ${this.props.title}`} element={<Button style={{ backgroundColor: "#144681", color: "white" }} for="download-excel" role="button"  >Descargar En Excel</Button>}>
                    <ExcelSheet data={this.state.excelData} name={`Claves de diplomado - ${this.props.title}`}>
                        <ExcelColumn label="Nombre" value="nombre" />
                        <ExcelColumn label="Fecha aplicado" value="fecha" />
                        <ExcelColumn label="Telefono" value="phoneNumber" />
                        <ExcelColumn label="Correo electrónico" value="email" />
                        <ExcelColumn label="Lugar de residencia" value="residence" />
                        <ExcelColumn label="CV" value="cv" />
                        <ExcelColumn label="¿Por qué quieres trabajar en SISU?" value="reasonForApplication" />
                        <ExcelColumn label="Sobre el candidato" value="selfDescription" />
                        <ExcelColumn label="Sueldo deseado" value="desiredSalary" />
                        <ExcelColumn label="Links adicionales" value="links" />
                        {this.state.extraQuestions.map(question => {
                            return <ExcelColumn label={question} value={question} />
                        })

                        }
                    </ExcelSheet>
                </ExcelFile>
            );
    }
}
export default DownloadExcel