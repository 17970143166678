import React, { Component } from 'react';
import fire from '../firebase';

class JobPostTimer extends Component {

    constructor(props) {
        super(props);
        this.state = { mountedTimestamp: -1, days: 0, hours: 0, minutes: 0, seconds: 0, intervalId: '', elapsedTime: 0, rechazado: false, doOnce: true };
    }

    componentDidMount() {

        let intervalId = setInterval(() => this.setLeftTime(), 1000);

        var getTimestamp = fire.functions().httpsCallable('getTimeStampCallable');
        getTimestamp().then((result) => {

            this.setState({ mountedTimestamp: result.data.timestamp }, () => {
                this.setLeftTime()
            })

        }).catch(error => {
            console.log('error: ' + error)
            this.setState({ timeLeft: 'N/A' })
        })

        this.setState({ ...this.state, intervalId });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    setLeftTime() {
        let dueDate = this.props.dueDate

        let leftTime = dueDate - (this.state.mountedTimestamp + this.state.elapsedTime);

        let seconds = Math.floor((leftTime / 1000) % 60);
        let minutes = Math.floor((leftTime / 1000 / 60) % 60);
        let hours = Math.floor(leftTime / (1000 * 60 * 60) % 24);
        let days = Math.floor(leftTime / (1000 * 60 * 60 * 24));

        if (leftTime < 0) 
            this.setState({ rechazado: true });
        else
            this.setState({ rechazado: false });
        this.setState({ days, hours, minutes, seconds, elapsedTime: this.state.elapsedTime + 1000 });

    }
    formatLeftTimeText(number, noun) {
        return number !== 1 ? `${noun}s` : noun;
    }
    formatLeftTime(number) {
        return number >= 0 ? number : 0;
    }

    render() {
        if (this.props.dueDate === -1) {
            return (
                <div>
                </div>
            );
        }
        else {
            if (this.state.mountedTimestamp === -1) {
                return (
                    <div>
                    </div>
                );
            }
            else {
                return (
                    <div>
                        {this.state.rechazado && <span>Rechazado por tiempo</span>
                        }
                        {!this.state.rechazado && <span>{(() => { return `${this.formatLeftTime(this.state.days)} ${this.formatLeftTimeText(this.state.days, 'día')}, ` })()}</span>}
                        {!this.state.rechazado && <span>{(() => { return `${this.formatLeftTime(this.state.hours)}h, ` })()}</span>}
                        {!this.state.rechazado && <span>{(() => { return `${this.formatLeftTime(this.state.minutes)}m, ` })()}</span>}
                        {!this.state.rechazado && <span>{(() => { return `${this.formatLeftTime(this.state.seconds)}s.` })()}</span>}
                    </div>
                );
            }

        }

    }

}


export default JobPostTimer;
