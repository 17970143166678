import React, { Component, } from 'react';

import { VacanteForm, VacanteTitle } from "./components";

import fire from '../../firebase';
import TopBar from '../../components/TopBar'

import { convertToRaw, ContentState, convertFromHTML, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

var pruebaSelectionOptions = []
var thePruebas = []

class EditVacante extends Component {

    INITIAL_STATE = {
        activado: this.props.location.activado,
        jobpostId: this.props.match.params.jobpostid,
        nombre: this.props.location.nombre,
        labelColor: this.props.location.labelColor,
        fullDescriptionHTML: this.props.location.fullDescriptionHTML,
        simpleDescription: this.props.location.simpleDescription,
        editorState: undefined,
        isLoading: false,

        extraQuestions: this.props.location.extraQuestions || [],
    }

    constructor(props) {
        super(props);
        console.log(this.props.location.labelColor)

        if (this.props.location.fullDescriptionHTML) {
            this.state = {
                ...this.INITIAL_STATE,
                editorState: EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(this.props.location.fullDescriptionHTML)))
            };
        }
        else {
            this.state = {
                ...this.INITIAL_STATE,
            };
        }

    }

    componentDidMount() {
        this.setVacanteVariables()
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState
        });
    };


    setVacanteVariables = () => {
        let db = fire.firestore();
        
        if (!this.props.match.params.openingFromButton) {
            db.collection('jobposts').doc(this.state.jobpostId).get().then((doc) => {             
                this.setState({
                    activado: doc.data().activado,
                    nombre: doc.data().nombre,
                    labelColor: doc.data().labelColor,
                    fullDescriptionHTML: doc.data().fullDescriptionHTML,
                    simpleDescription: doc.data().simpleDescription,
                    editorState: EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(doc.data().fullDescriptionHTML))),
                    extraQuestions: doc.data().extraQuestions || []            
                });
            });
        }
    }

    handleFormInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    addQuestion = () => {
        var extraQuestions = this.state.extraQuestions
        extraQuestions.push({ question: "", optionRequired: true, optionTextInput: true, })
        this.setState({ extraQuestions: extraQuestions })
    }
    removeQuestion = (index) => {
        var extraQuestions = this.state.extraQuestions
        extraQuestions.splice(index, 1)
        this.setState({ extraQuestions: extraQuestions })
    }
    handleFormQuestionInputChange = (event, index) => {
        var extraQuestions = this.state.extraQuestions
        extraQuestions[index].question = event.target.value
        this.setState({ extraQuestions: extraQuestions })
    }
    handleOptionRequired = (selectedOption, index) => {
        var extraQuestions = this.state.extraQuestions
        extraQuestions[index].optionRequired = selectedOption.value
        this.setState({ extraQuestions: extraQuestions })
    }
    handleOptionTextInput = (selectedOption, index) => {
        var extraQuestions = this.state.extraQuestions
        extraQuestions[index].optionTextInput = selectedOption.value
        this.setState({ extraQuestions: extraQuestions })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        let db = fire.firestore();
        this.setState({isLoading: true})

        db.collection('jobposts').doc(this.state.jobpostId).set(
            {
                nombre: this.state.nombre,
                labelColor: this.state.labelColor,
                fullDescriptionHTML: "" + draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
                simpleDescription: this.state.simpleDescription,
                activado: this.state.activado,
                extraQuestions: this.state.extraQuestions || []
            },
            {
                merge: true
            }).then(docRef => {
                this.setState({isLoading: false})
                this.props.history.push({ pathname: '/vacantes' })
            })
            .catch(error => {
                this.setState({isLoading: false})
                alert(error.code);
            });
    }

    render() {

        return (
            <div id="admin">
                <TopBar />

                <VacanteTitle nombre={this.state.nombre}
                    labelColor={this.state.labelColor}
                    activado={this.state.activado}
                    editingVacante={true}
                    toggleActivateVacante={() => this.setState({ activado: !this.state.activado })}
                    activadoText={this.state.activado ? 'Vacante activa' : 'Vacante inactivo'} />

                {this.state.editorState &&
                <VacanteForm handleSubmit={this.handleSubmit}
                    handleFormInputChange={this.handleFormInputChange}
                    nombre={this.state.nombre}
                    labelColor={this.state.labelColor}
                    simpleDescription={this.state.simpleDescription}
                    onEditorStateChange={this.onEditorStateChange}
                    defaultEditorState={this.state.editorState} 
                    isLoading={this.state.isLoading}
                    
                    addQuestion={this.addQuestion}
                    removeQuestion={this.removeQuestion}
                    handleFormQuestionInputChange={this.handleFormQuestionInputChange}
                    extraQuestions={this.state.extraQuestions}
                    handleOptionRequired={this.handleOptionRequired}
                    handleOptionTextInput={this.handleOptionTextInput} />}

            </div>
        );
    }
}

export default EditVacante;