import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { Link, animateScroll as scroll } from "react-scroll";

const CareersIntro = (props) => {
    return (

        <section id="careers-intro" className="backChange full-section b-img" >
            <div className="bg-wrap"><div className="fixed-img"  ></div></div>
            <div className="row  align-middle align-center text-center ">
                <div className="columns small-11 ">
                    <h1 className="fs-3 fw-5 mb-02">Sé parte de un equipo talentoso</h1>
                    <p className="fs-6 fw-5"><span className="noWrap">Resuelve problemas.</span> <span className="noWrap">Construye el futuro.</span> <span className="">Aprende y mejora todos los días.</span></p>
                    <Link to="careers-jobs" smooth={true}  duration={700} className="scrolling-pill">
                      
                         <FontAwesomeIcon className="button__icon icon upleft" icon={faArrowDown} />

                        <span>Ir directo a vacantes</span>
                        
                    </Link>
                </div>

            </div>
        </section>
    );
};

export default CareersIntro;