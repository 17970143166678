import React, { Component, } from 'react';

import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import fire from '../firebase';
import TopBar from '../components/TopBar'

import moment from 'moment'
import 'moment/locale/es';

import DeleteIcon from '@material-ui/icons/Delete'
import ReactTable from 'react-table'
//import "react-table/react-table.css";

import JobPostTimer from './JobPostTimer';

var data = []
var dataID = []
var unsubscribe = ''
let deleted = false
class Candidatos extends Component {

    INITIAL_STATE = {
        nombre: '',
        residencia: '',
        contacto: '',
        referencias: '',
        vacante: '',
        fase: '',
        fechaActualización: '',
        timeLeft: '',
        failedCandidates: [],
        deleted: false
    }

    constructor(props) {
        super(props);
        this.state = { ...this.INITIAL_STATE };
    }
    componentWillUnmount() {
        //unsubscribe from query snapshot listener
        unsubscribe();
    }
    componentDidMount() {
        this.setState({ ...this.INITIAL_STATE })
        let db = fire.firestore();

        unsubscribe = db.collection('jobposts').onSnapshot((querySnapshot) => {
            this.setState({ data: [] })
            data = []
            dataID = []
            querySnapshot.forEach((doc) => {
                if (doc.data().showCandidates) {
                    db.collection('jobposts').doc(doc.id).collection('candidates').onSnapshot((allCandidates) => {
                        if (deleted) {
                            this.setState({ data: [] })
                            data = []
                            deleted = false
                        }
                        allCandidates.forEach((candidate) => {
                            var candidateFase = "Aplicó"
                            var allPruebas = candidate.data().pruebas || []
                            var dueDate = -1

                            if (allPruebas.length > 0) {
                                if (allPruebas[allPruebas.length - 1].phase === 1) {
                                    candidateFase = 'Prueba pre-inicio'
                                    dueDate = allPruebas[allPruebas.length - 1].dueDateBeginTest
                                }
                                else if (allPruebas[allPruebas.length - 1].phase === 2) {
                                    candidateFase = 'Haciendo prueba en casa'
                                    dueDate = allPruebas[allPruebas.length - 1].dueDateCompleteTest
                                    //   if(candidate.data().name.split(' ')[0] === "Moises")
                                    //       window.alert(dueDate - Date.now())
                                }

                                if (allPruebas[allPruebas.length - 1].phase === 3) {
                                    candidateFase = 'Prueba Enviada'
                                    dueDate = -1
                                }
                            }//assumption pruebas are consecutive not parallel

                            //if(candidateFase === 'Rechazado')
                            //   candidateFase = 'Rechazado\n' + "(" + ApplicationPhase[ApplicationPhase.length - 1].reason + ")"

                            if (!dataID.includes(candidate.id)) {
                                data.push({
                                    nombre: { doc: doc, candidate: candidate },
                                    residencia: candidate.data().residence,
                                    contacto: { email: candidate.data().email, phone: candidate.data().phoneNumber },
                                    referencias: <div><a rel="noopener noreferrer" target="_blank" href={candidate.data().cv}>Descargar CV</a></div>,
                                    vacante: doc.data().nombre,
                                    fase: { dueDate: dueDate, candidateFase: candidateFase, candidate: candidate },
                                    fechaActualización: {
                                        fecha: candidateFase === "Aplicó" ? moment(candidate.data().appliedTimestamp, "x").locale('es').format("DD MMM YYYY HH:mm")
                                            : moment(allPruebas[allPruebas.length - 1].timestamps[allPruebas[allPruebas.length - 1].timestamps.length - 1], "x").locale('es').format("DD MMM YYYY HH:mm"),
                                        timestamp: candidateFase === "Aplicó" ? candidate.data().appliedTimestamp
                                            : allPruebas[allPruebas.length - 1].timestamps[allPruebas[allPruebas.length - 1].timestamps.length - 1]
                                    },
                                    Borrar: <Button onClick={() => {
                                        if (window.confirm("¿Estás seguro de que deseas eliminar este candidatos?")) {
                                            /*doc.id, doc.data().nombre, doc.data().challengeFileName*/
                                            this.deleteUserAndFiles(candidate.data().cvFilename, allPruebas, doc.id, candidate.id)
                                        }
                                    }}><DeleteIcon /></Button>
                                })
                                dataID.push(candidate.id)
                            }

                            this.setState({ data: data })
                        })
                    })
                }
            });
        });
    }

    deleteUserAndFiles = (cvFilename, allPruebas, docId, candidateId) => {
        deleted = true
        var deleteRef = fire.storage().ref().child(`cv/${cvFilename}`);
        deleteRef.delete().then(() => {

            this.deleteAllPruebas(allPruebas)


            //window.alert("Borrado con exito...")
        }).catch(error => {
            //window.alert(error.message)
            console.log(error.message)
        });

        let db = fire.firestore();
        db.collection('jobposts').doc(docId).collection('candidates').doc(candidateId).delete().then(() => {
            console.log("Document successfully deleted!");
            window.alert("Candidato eliminado con éxito!")


        }).catch((error) => {
            window.alert("Error removing document: " + error)
            console.error("Error removing document: ", error);
        });

    }
    deleteAllPruebas = (allPruebas) => {
        let deletePruebaSolucionRef = ""
        allPruebas.forEach(prueba => {
            if (prueba.phase === 3) {
                deletePruebaSolucionRef = fire.storage().ref().child(`soluciones-candidatos/${prueba.uploadedHomeworkFilename}`);
                deletePruebaSolucionRef.delete().then(() => {
                    //window.alert("Borrado con exito...")
                }).catch(error => {
                    //window.alert(error.message)
                    console.log(error.message)
                });
            }
        })

    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
                :
                true
        );
    }

    failRef = (candidateID) => {
        var failed = this.state.failedCandidates
        failed.push(candidateID)
        this.setState({ failedCandidates: failed })
    }

    render() {

        const columns = [{
            id: "nombre",
            Header: 'Nombre',
            filterable: true,
            accessor: d => { return d.nombre.candidate.data().name },

            Cell: e => <Link to={`/candidato-perfil/${e.original.nombre.candidate.id}/${e.original.nombre.doc.id}/${e.original.nombre.doc.data().nombre}`}
                params={{
                    candidate: e.original.nombre.candidate.data(),
                    jobpost: e.original.nombre.doc.data().nombre

                }}>{e.original.nombre.candidate.data().name}</Link>,

        }, {
            id: "residencia",
            Header: 'Residencia',
            accessor: 'residencia',
            filterable: true,
        }, {
            id: "contacto",
            Header: 'Contacto',
            filterable: true,
            accessor: d => { return d.contacto.email },
            Cell: e => <div>{e.original.contacto.email}<div>{e.original.contacto.phone}</div></div>

        }, {
            Header: 'Referencias',
            accessor: 'referencias'
        }, {
            Header: 'Vacante',
            accessor: 'vacante',
            filterable: true,
        }, {
            id: "fase",
            Header: 'Fase',
            filterable: true,
            accessor: d => { return d.fase.candidateFase + (!this.state.failedCandidates.includes(d.nombre.candidate.id) ? "" : "rechazado por tiempo") },

            Cell: e => <div>{e.original.fase.candidateFase}{e.original.fase.candidateFase !== "Aplicó" && <div><JobPostTimer dueDate={e.original.fase.dueDate} candidateID={e.original.nombre.candidate.id} /></div>}</div>,
        }, {
            id: "Fecha Actualización",
            Header: 'Fecha Actualización',
            accessor: d => { return d.fechaActualización.timestamp },
            Cell: e => <div>{e.original.fechaActualización.fecha}</div>,
        }, {
            Header: 'Borrar',
            accessor: 'Borrar'
        }]

        return (
            <div id="admin">

                <TopBar />

                <section id="page-title">
                    <div class="row">
                        <div class="columns medium-12  ">
                            <h1>Candidatos</h1>
                        </div>
                    </div>
                </section>
                <section id="admin-feed" class="">
                    <div class="row align-center align-middle ">
                        <div class="columns medium-12  ">

                            <ReactTable
                                data={data}
                                columns={columns}
                                defaultPageSize={10}
                                pageSizeOptions={[10, 20]}
                                className="-striped -highlight"
                                resizable
                                defaultFilterMethod={(filter, row) => {
                                    let r = row[filter.id].toUpperCase().includes(filter.value.toUpperCase());
                                    return r;//return  matchSorter(rows, filter.value, { keys: ["lastName"] })}
                                }}
                            />

                        </div>
                    </div>
                </section>
                <div class="row align-right">
                    <div class="columns shrink">
                        <Button style={{ backgroundColor: "#144681", color: "white" }} onClick={() => this.props.history.push({ pathname: '/nuevo-candidato' })} class="button" >
                            Agregar nuevo Candidato
                        </Button>
                    </div>
                </div>

            </div>
        );
    }
}


export default Candidatos;