import React, { Component } from 'react';
import Button from '@material-ui/core/Button';

import TopBar from '../components/TopBar'
import moment from 'moment'
import 'moment/locale/es';

import fire from '../firebase';

let feed = []
var unsubscribe = ''

class Feed extends Component {

    INITIAL_STATE = { email: '', password: '', errorMessage: null }

    constructor(props) {
        super(props);
        this.state = { ...this.INITIAL_STATE };
    }
    componentWillUnmount() {
        //unsubscribe from query snapshot listener
        unsubscribe();
    }
    componentDidMount() {
        
        let db = fire.firestore();

        unsubscribe = db.collection('recentActivity').orderBy("timestamp", "desc").onSnapshot((recentActivities) => {
            var index = 0
            feed = []
            recentActivities.forEach((activity) => {
                if (index < 25) {
                    feed.push(activity.data())
                }
                index++
            })
            this.setState({})
        })

    }

    initializeFeed() {

    }


    render() {
        let theFeed = feed.map((activity, i) => {
            return (
                <div class="feed-post">
                    <div class="feed-post__title">{activity.activity} - {activity.jobpost}</div>
                    <div class="feed-post__date">{moment(activity.timestamp, "x").locale('es').format("DD MMM YYYY HH:mm")}</div>
                    <div class="feed-post__name"><a href='' onClick={() => this.props.history.push({
                        pathname: `/candidato-perfil/${activity.candidateId}/${activity.jobpostId}/${activity.jobpost}`,
                        candidate: activity.candidateId,
                        jobpost: activity.jobpost

                    })}>{activity.name}</a></div>
                </div>
            )

        })

        return (
            <div id="admin">
                <TopBar />
                <body id="admin">

                    <section id="admin-feed" class="">
                        <div class="row align-center align-middle ">
                            <div class="columns medium-12  ">



                                <h1>Actividad reciente</h1>


                                <div class="feed">
                                    {theFeed}
                                </div>

                            </div>
                        </div>
                    </section>
                </body>
            </div>


        );
    }
}

export default Feed;