import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Feed from './srcAdmin/containers/Feed';
import AdminLogin from './srcAdmin/containers/AdminLogin';
import Candidatos from './srcAdmin/containers/Candidatos';
import Pruebas from './srcAdmin/containers/Pruebas/Pruebas';
import EditPrueba from './srcAdmin/containers/Pruebas/EditPrueba';
import Vacantes from './srcAdmin/containers/Vacantes/Vacantes';
import NewVacante from './srcAdmin/containers/Vacantes/NewVacante';
import NewCandidate from './srcAdmin/containers/NewCandidate';
import EditVacante from './srcAdmin/containers/Vacantes/EditVacante';
import NewPrueba from './srcAdmin/containers/Pruebas/NewPrueba';
import CandidateProfile from './srcAdmin/containers/CandidateProfile';
import EditCandidate from './srcAdmin/containers/EditCandidate';
import Correos from './srcAdmin/containers/Correos2';

import FirstPage from './srcCandidates/containers/FirstPage/FirstPage';
import JobPostDetail from './srcCandidates/containers/JobPostDetail/JobPostDetail';
import JobPostForm from './srcCandidates/containers/JobPostForm/JobPostForm';
import PruebaDashboard from './srcCandidates/containers/PruebaDashboard/PruebaDashboard';

import fire from './firebase';


export default class Routes extends Component {

    constructor(props) {
        super(props);
        this.state = { loggedIn: false, isLoading: true };
    }

    componentDidMount() {
        fire.auth().onAuthStateChanged(user => {
            if (user) {
                this.setState({ loggedIn: true, isLoading: false })
            } else {
                this.setState({ loggedIn: false, isLoading: false })
            }
        })
    }
    render() {
        if (this.state.loggedIn) {
            return (
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/" component={FirstPage} />
                        <Route path="/jobpost/:id/detail" component={JobPostDetail} />
                        <Route path="/jobpost/apply/:id" component={JobPostForm} />
                        <Route path="/prueba-dashboard/:jobpost/:pruebaIndex/:candidate/" component={PruebaDashboard} />


                        <Route exact path="/admin" component={Feed} />
                        <Route path="/Feed" component={Feed} />
                        <Route path="/Candidatos" component={Candidatos} />
                        <Route path="/NewVacante" component={NewVacante} />
                        <Route path="/Pruebas" component={Pruebas} />
                        <Route path="/editar-prueba" component={EditPrueba} />
                        <Route path="/Vacantes" component={Vacantes} />
                        <Route path="/nueva-vacante" component={NewVacante} />
                        <Route path="/nuevo-candidato" component={NewCandidate} />
                        <Route path="/editar-vacante/:jobpostid" component={EditVacante} />
                        <Route path="/nueva-prueba" component={NewPrueba} />
                        <Route path="/correos" component={Correos} />
                        <Route path="/candidato-perfil/:id/:jobpostid/:jobpost" component={CandidateProfile} />
                        <Route path="/editar-candidato/:id/:jobpostid/:jobpost" component={EditCandidate} />

                        <Route path="/:anything" render={() => <h1>Page not found</h1>} />
                    </Switch>
                </BrowserRouter>
            );

        } else {
            if (this.state.isLoading) {
                return (
                    <div></div>
                );
            }
            else {
                return (
                    <BrowserRouter>
                        <Switch>
                            <Route exact path="/" component={FirstPage} />
                            <Route path="/jobpost/:id/detail" component={JobPostDetail} />
                            <Route path="/jobpost/apply/:id" component={JobPostForm} />
                            <Route path="/prueba-dashboard/:jobpost/:pruebaIndex/:candidate/" component={PruebaDashboard} />


                            <Route exact path="/admin" render={() => <AdminLogin></AdminLogin>} />
                            <Route path="/Feed" render={() => <AdminLogin></AdminLogin>} />
                            <Route path="/Candidatos" render={() => <AdminLogin></AdminLogin>} />
                            <Route path="/NewVacante" render={() => <AdminLogin></AdminLogin>} />
                            <Route path="/Pruebas" render={() => <AdminLogin></AdminLogin>} />
                            <Route path="/editar-prueba" render={() => <AdminLogin></AdminLogin>} />
                            <Route path="/Vacantes" render={() => <AdminLogin></AdminLogin>} />
                            <Route path="/nueva-vacante" render={() => <AdminLogin></AdminLogin>} />
                            <Route path="/nuevo-candidato" render={() => <AdminLogin></AdminLogin>} />
                            <Route path="/editar-vacante/:jobpostid" render={() => <AdminLogin></AdminLogin>} />
                            <Route path="/nueva-prueba" render={() => <AdminLogin></AdminLogin>} />
                            <Route path="/correos" render={() => <AdminLogin></AdminLogin>} />
                            <Route path="/candidato-perfil/:id/:jobpostid/:jobpost" render={() => <AdminLogin></AdminLogin>} />
                            <Route path="/editar-candidato/:id/:jobpostid/:jobpost" render={() => <AdminLogin></AdminLogin>} />
                            
                            <Route path="/:anything" render={() => <h1>Page not found</h1>} />

                        </Switch>
                    </BrowserRouter>
                );
            }
        }

    }
} 