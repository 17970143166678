import React from 'react';
import JobPostList from '../../../components/JobPostList';

const CareersJobs = (props) => {
    return (

        <section id="careers-jobs" className="backChange section--widemargin ">
        <div className="row align-center ">
            <div className="columns small-12 medium-8  ">
                <h2 className="fs-4 fw-6 text-center mb-10 " >
                     Estamos buscando
                </h2>

                <JobPostList vacantes={props.vacantes} />


            </div>
        </div>
    </section>
    );
};

export default CareersJobs;