import React from 'react';
import Dropzone from 'react-dropzone'
import { CircularProgress } from 'material-ui/Progress';
import Close from 'material-ui-icons/Close';
import Add from 'material-ui-icons/Add';
import Button from 'material-ui/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileUpload, faCloudUploadAlt, faTrash, faFileAlt } from '@fortawesome/free-solid-svg-icons'


const ApplyForm = (props) => {
    return (
        <div>
            <section id="apply-title" className="backChange  threequarter-section   b-color bg-1" >

                <div className=" row  align-middle align-center text-center ">
                    <div className="columns small-12  text-center ">
                        <h1 className="fs-3 fw-5">{props.vacanteName}</h1>
                        <h2 className="fs-6 fw-5">Aplicar a vacante</h2>
                    </div>
                </div>
            </section>

            <section id="apply-form" className=" backChange  ">
                <div className="row align-center ">
                    <div className="columns medium-7  small-centered">

                        <form onSubmit={props.handleSubmit} encType="multipart/form-data" autoComplete="none">
                            <div className="grid-container">
                                <div className="grid-x grid-padding-x">



                                    <fieldset >
                                        <legend><strong>¿Cómo te contactamos?</strong></legend>

                                        <div className="row align-center ">
                                            <div className="columns medium-11  small-centered">

                                                <div className="medium-12 cell inputcontainer">

                                                    <input name="name" autoComplete="none"
                                                        type="text"
                                                        placeholder="Nombre completo *"
                                                        value={props.name}
                                                        onChange={props.handleFormInputChange}
                                                        required="true"
                                                        onInvalid={(e) => e.target.setCustomValidity("Debes llenar este campo")} onInput={(e) => e.target.setCustomValidity('')}
                                                    />
                                                    <label>Nombre completo <span className="required">*</span></label>
                                                </div>

                                                <div className="medium-12 cell inputcontainer">

                                                    <input name="email" autoComplete="none"
                                                        type="email" placeholder="Correo electrónico *"
                                                        value={props.email} onChange={props.handleFormInputChange}
                                                        required="true"
                                                        onInvalid={(e) => {
                                                            if (e.target.value.length === 0) {
                                                                e.target.setCustomValidity("Debes llenar este campo")
                                                            }
                                                            else if (e.target.value.includes("@") && e.target.value.includes(".")) {
                                                                e.target.setCustomValidity("'.'se utiliza en una posición incorrecta en: '" + e.target.value + "'")
                                                            }
                                                        }}
                                                        onInput={(e) => {
                                                            var str = e.target.value;
                                                            var res = str.split("@");

                                                            if (!e.target.value.includes("@")) {
                                                                e.target.setCustomValidity('Debe tener un @')
                                                            }
                                                            else if (!e.target.value.includes(".")) {
                                                                e.target.setCustomValidity('Debe tener un .')
                                                            }
                                                            else if (res[1] === '.' || res[1].charAt(0) === '.' || res[1].charAt(res[1].length - 1) === '.') {
                                                                e.target.setCustomValidity("'.'se utiliza en una posición incorrecta en: '" + e.target.value + "'")
                                                            }
                                                            else {
                                                                e.target.setCustomValidity('')
                                                            }
                                                        }}
                                                    />
                                                    <label>Correo electrónico <span className="required">*</span></label>
                                                </div>


                                                <div className="medium-12 cell inputcontainer">

                                                    <input name="phoneNumber" type="tel" autoComplete="none"
                                                        placeholder="Teléfono *"
                                                        value={props.phoneNumber}
                                                        onChange={props.handleFormInputChange}
                                                        required="true"
                                                        minLength={10}
                                                        pattern="[0-9\-()]+"
                                                        title="El número solo puede contener números, guiones -, y paréntesis ()"
                                                        onInvalid={(e) => {
                                                            if (e.target.value.length === 0)
                                                                e.target.setCustomValidity("Debes llenar este campo")
                                                            else if (e.target.value.length < 10)
                                                                e.target.setCustomValidity('Debe tener un mínimo de 10 numeros.')
                                                            else
                                                                e.target.setCustomValidity('El número solo puede contener números, guiones -, y paréntesis ()')


                                                        }}
                                                        onInput={(e) => {
                                                            if (e.target.value.length < 10)
                                                                e.target.setCustomValidity('Debe tener un mínimo de 10 numeros.')
                                                            else
                                                                e.target.setCustomValidity('')
                                                        }
                                                        }
                                                    />
                                                    <label>Teléfono <span className="required">*</span></label>
                                                </div>

                                                <div className="medium-12 cell inputcontainer">


                                                    <input name="residence" type="text" autoComplete="none"
                                                        placeholder="Lugar de residencia actual (Ciudad, Estado, País) *"
                                                        value={props.residence}
                                                        onChange={props.handleFormInputChange}
                                                        required="true"
                                                        onInvalid={(e) => e.target.setCustomValidity("Debes llenar este campo")} onInput={(e) => e.target.setCustomValidity('')}
                                                    />
                                                    <label>Lugar de residencia actual (Ciudad, Estado, País) <span className="required">*</span></label>
                                                </div>
                                                <input name="hidden" type="text" style={{ display: 'none' }} placeholder="Country" />
                                            </div>
                                        </div>
                                    </fieldset>

                                    <fieldset >
                                        <legend><strong>Queremos conocerte y ver lo que has hecho! (esto es opcional pero apreciado)</strong></legend>


                                        <div className="row align-center ">
                                            <div className="columns medium-11  small-centered">

                                                <div className="medium-12 cell inputcontainer">

                                                    <textarea rows="3" name="reasonForApplication"
                                                        placeholder="¿Por qué quieres trabajar en SISU?"
                                                        onChange={props.handleFormInputChange}

                                                    /><label>¿Por qué quieres trabajar en SISU?</label>
                                                </div>


                                                <div className="medium-12 cell ">
                                                    <label>Links relevantes (Github, Behance, proyectos, página personal, Linkedin)</label>

                                                    {props.links.map((link, index) => {
                                                        return (
                                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                <input type="text"
                                                                    placeholder="URL"
                                                                    value={link}
                                                                    onChange={(e) => props.handleFormLinkInputChange(e, index)}
                                                                />
                                                                {index === props.links.length - 1 && <Button onClick={props.addLink} className="button-input  button  button--text-thick button--border-medium button--text-upper button--size-s " type="" ><Add /></Button>}
                                                                {index !== 0 && <Button onClick={() => props.removeLink(index)} className="button-input button  button--text-thick button--border-medium button--text-upper button--size-s  " type="" ><Close /></Button>}

                                                            </div>
                                                        )

                                                    })}
                                                </div>

                                                <div className="medium-12 cell inputcontainer mt-0">

                                                    <textarea rows="3" name="selfDescription" placeholder="¿Quieres compartirnos algo más sobre ti?"
                                                        value={props.selfDescription}
                                                        onChange={props.handleFormInputChange}

                                                    ></textarea><label>¿Quieres compartirnos algo más sobre ti?</label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset >
                                    <fieldset >

                                        <legend><strong>Unos últimos detalles...</strong></legend>

                                        <div className="row align-center ">
                                            <div className="columns medium-11  small-centered">

                                                <div className="medium-12 cell inputcontainer">

                                                    <input name="desiredSalary" type="text" autoComplete="nope"
                                                        placeholder="Expectativa salarial *"
                                                        value={props.desiredSalary}
                                                        onChange={props.handleFormInputChange}
                                                        required="true"
                                                        onInvalid={(e) => e.target.setCustomValidity("Debes llenar este campo")} onInput={(e) => e.target.setCustomValidity('')}
                                                    /><label>Expectativa salarial <span className="required">*</span></label>
                                                </div>

                                                <Dropzone
                                                    onDrop={props.handleOnDrop}
                                                    multiple={false}
                                                    maxSize={11111111}
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <div>
                                                            <label>Adjuntar CV en formato PDF</label>
                                                            <div {...getRootProps()} className="dropzone">



                                                                {props.fileName === "ningún archivo elegido" ?
                                                                    <FontAwesomeIcon className="button__icon icon" icon={faCloudUploadAlt} />
                                                                    :
                                                                    <FontAwesomeIcon className="button__icon icon" icon={faFileAlt} />
                                                                }
                                                                <input {...getInputProps()} accept="application/pdf" />
                                                                <div className="dz-msg">
                                                                    <div className="dz-instruction">
                                                                        {props.fileName === "ningún archivo elegido" ?
                                                                            <span className="upper">Haz clic aquí o arrastra tu archivo</span> :
                                                                            <span>{props.fileName}</span>}
                                                                    </div>

                                                                    {props.fileName === "ningún archivo elegido" ?
                                                                        "" :
                                                                        <span className="dz-file">Archivo a subir</span>
                                                                    }


                                                                </div>

                                                            </div>
                                                        </div>
                                                    )}
                                                </Dropzone>

                                                {props.fileName === "ningún archivo elegido" ? "" :
                                                    <a className="expanded button button--shikoba button--text-thick button--border-medium button--text-upper button--size-l" onClick={props.removeFile}>
                                                        <FontAwesomeIcon className="button__icon icon" icon={faTrash} />
                                                        <span>Eliminar archivo elegido</span>
                                                    </a>
                                                }
                                        <legend><strong>Preguntas adicionales</strong></legend>

                                                <div className="medium-12 cell ">
                                                    {props.extraQuestions.map((question, index) => {
                                                       //window.alert(question.optionRequired + " - " + index)
                                                        let required = question.optionRequired ? ' *' : ''
                                                        let placeholder = question.question + required
                                                        return (
                                                            <div >
                                                                {question.optionTextInput &&<div className="medium-12 cell inputcontainer"> <input  type="text" autoComplete="nope"
                                                                    placeholder={placeholder}
                                                                    value={question.answer}
                                                                    onChange={(e) => props.handleFormInputQuestionChange(e, index)}
                                                                    required={question.optionRequired}
                                                                    onInvalid={(e) => e.target.setCustomValidity("Debes llenar este campo")} onInput={(e) => e.target.setCustomValidity('')}
                                                                /><label>{question.question} {question.optionRequired && <span className="required">*</span>}</label></div> }
                                                                
                                                                {!question.optionTextInput && <div className="medium-12 cell inputcontainer"> <textarea rows="3"  
                                                                    placeholder={placeholder}
                                                                    value={question.answer}
                                                                    required={question.optionRequired}
                                                                    onChange={(e) => props.handleFormInputQuestionChange(e, index)}
                                                                    onInvalid={(e) => e.target.setCustomValidity("Debes llenar este campo")} onInput={(e) => e.target.setCustomValidity('')}
                                                                ></textarea><label>{question.question} {question.optionRequired && <span className="required">*</span>}</label></div> }
                                                            </div>
                                                        )
                                                    })}
                                                </div>


                                            </div>
                                        </div>
                                    </fieldset>


                                </div>
                            </div>

                            <div className="medium-12 cell ">

                                <div className="checkbox">
                                    <input name="acceptPrivacyTerms" id="checkbox2"
                                        type="checkbox"
                                        value={props.acceptPrivacyTerms}
                                        onChange={props.handleFormCheckboxChange}
                                        onInvalid={(e) => e.target.setCustomValidity("Debes seleccionar esto")} onInput={(e) => e.target.setCustomValidity('')}
                                    />
                                    <label for="checkbox2">Leí y acepto los <a href="#" onClick={props.openPrivacyModal} target="_blank"  >términos de privacidad </a></label>
                                </div>

                            </div>




                            <button type="submit" disabled={props.enviandoFormulario} className="expanded button button--shikoba button--text-thick button--border-medium button--text-upper button--size-l">

                                <FontAwesomeIcon className="button__icon icon" icon={faFileUpload} />

                                <span>Enviar mi aplicación</span>
                            </button>


                            {props.enviandoFormulario && <CircularProgress size={24} />}
                            {props.enviandoFormulario && <span> {props.fileStatus.state}% </span>}

                        </form>
                    </div>
                </div>
            </section>

        </div>
    );
};

export default ApplyForm;