import React from 'react';

const Phase3 = (props) => {
    return (
        	<div className="dashboard-event">
        		<div className="card">
                	<div className="dashboard-event__date">
                		{props.phase3TimeFormatted}
                	</div>
                    <div className="dashboard-event__title ">
                    Prueba enviada con éxito
                    </div>
                        <div className="dashboard-event__content">
                        	<p>Gracias por subir tu solución de la prueba en casa, estamos evaluandola y estaremos en contacto contigo pronto.</p>
                        </div>
                	
                </div>
            </div>
    );
};

export default Phase3;