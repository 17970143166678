import React from 'react';
import fire from '../firebase';


const TopBar = (props) => {
    return (

        <div class="top-bar white" id="menu" data-interchange="[../assets/img/common/blue1.jpg, small]" style={{backgroundSize: "cover", backgroundImage: "url('/assets/img/common/blue1.jpg')", width: '100%' }} >

            <div class="top-bar-title">

                <a href={'/feed'}><div class="logo careers"></div></a>

            </div>

            <div id="responsive-menu">
                <div class="top-bar-right">
                    <ul class="menu" >
                        <li><a href={'/feed'}>Inicio</a></li>
                        <li><a href={'/vacantes'}>Vacantes</a></li>
                        <li><a href={'/candidatos'}>Candidatos</a></li>
                        <li><a href={'/pruebas'}>Pruebas</a></li>
                        <li><a href={'/correos'}>Correos</a></li>
                        {!props.loginScreen && <li><a href="#" onClick={() => {

                            fire.auth().signOut().then(() => {
                                console.log('Signed out')
                            }).catch(error => {
                                window.alert(error.message)
                                console.log(error)
                            });
                        }}>Cerrar sesión</a></li>}
                    </ul>
                </div>
            </div>
        </div>

    );
};

export default TopBar;