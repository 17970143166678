import React from 'react';

import Button from '@material-ui/core/Button';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToRaw, ContentState, convertFromHTML, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { CircularProgress } from 'material-ui/Progress';

const EmailPruebaBody = (props) => {
  return (
    <div>
      <head>
        <title>Email</title>
        <link rel="stylesheet" type="text/css" href="css/style.css" />
      </head>
      <form onSubmit={props.saveEmailPruebaBody} encType="multipart/form-data">
        <div class="container" style={{ maxWidth: "800px", width: "100%" }}>

          <div dangerouslySetInnerHTML={{
            __html: `<div class="header center pt-10 pb-10 pl-9 pr-9" style='padding-left:9%;padding-right:9%;padding-top:10%;padding-bottom:10%;background:url("http://sisu.mx/images/vacantes/azul-correo.png");text-align:center;'>
                        <img class="logo margin-bottom-20" src="http://sisu.mx/images/vacantes/logo-blanco.png" style="margin-bottom:1.25em;max-width:80%;">
                        <p class="font-24 light" style='margin:0;font-size:1.125rem;line-height:1.2;font-family:"Lato",sans-serif;font-weight:300;font-size:24px;font-size:1.5rem;color:white;'>Prueba en casa: ` + props.displayOption + `</p>
                      </div>` }} />

          <div class="content pt-5 pb-5 pl-9 pr-9" style={{ paddingTop: '5%', paddingBottom: '5%', paddingLeft: '9%', paddingRight: '9%' }}>
            <p class="font-30 marginBottom-10" style={{ margin: 0, paddingSize: '1.125rem', lineHeight: 1.2, fontFamily: "Lato,sans-serif", paddingSize: '30px', paddingSize: '1.875rem', marginBottom: '.625em' }}>{">NOMBRE<"},</p>
            <textarea name="emailPruebaBody1" rows={5} placeholder="Cuerpo" id="0" style={{ marginBottom: '.625em', padding: 0, fontFamily: "Lato,sans-serif", resize: 'none', }}
              value={props.emailPruebaBody1}
              onChange={props.handlePruebaInputChange1}
              onFocus={props.onFocus}
              required="true"
            ></textarea>

            <a  class="center boton  light padding-10 font-25 margin-top-10 margin-bottom-10" style={{ textDecoration: 'none', fontFamily: "Lato,sans-serif", fontWeight: 300, fontSize: '25px', fontSiz: '1.5625rem', marginTop: '.625em', marginBottom: '.625em', padding: '.625em', textAlign: 'center', backgroundColor: '#EFEFEF', display: 'block' }} >Entrar a dashboard</a>
            <p class="font-20 light margin-bottom-10" style={{ margin: 0, paddingSize: '1.125rem', lineHeight: 1.2, fontFamily: "Lato,sans-serif", fontWeight: '300', fontSize: '20px', fontSize: '1.25rem', marginBottom: '.625em' }} >La prueba estará disponible para ti hasta el {props.writtenDate}. </p>

            <textarea name="emailPruebaBody2" rows={5} placeholder="Cuerpo" id="0" style={{ marginBottom: '.625em', padding: 0, fontFamily: "Lato,sans-serif", resize: 'none', }}
              value={props.emailPruebaBody2}
              onChange={props.handlePruebaInputChange2}
              onFocus={props.onFocus}
              required="true"
            ></textarea>
         
          </div>

          <div dangerouslySetInnerHTML={{
            __html: `<table class="footer pt-2 pb-2 pl-9 pr-9" style="padding-top:2%;padding-bottom:2%;padding-left:9%;padding-right:9%;width:100%;margin-top:1.2em;padding:3% 9%;margin:0;background:#424242;color:white;">
        <tr>
          <td>
            <img src="http://sisu.mx/images/vacantes/logo-sisu-bw.png?dl=0" style="max-width:80%;"/>
          </td>
          <td class="contactos" style="text-align:right;">
            <div class="contacto">
              <p class="name font-18 light" style='margin:0;font-size:1.125rem;line-height:1.2;font-family:"Lato",sans-serif;font-weight:300;font-size:18px;font-size:1.125rem;font-weight:400;'></p>
              <p class="email font-18 light" style='margin:0;font-size:1.125rem;line-height:1.2;font-family:"Lato",sans-serif;font-size:18px;font-size:1.125rem;font-weight:300;color:white;'><a style='color:black;font-family:"Lato",sans-serif;color:white;text-decoration:none;'></a></p>
              <p class="email font-18 light" style='margin:0;font-size:1.125rem;line-height:1.2;font-family:"Lato",sans-serif;font-size:18px;font-size:1.125rem;font-weight:300;color:white;'></p>
            </div>
          </td>
        </tr>
      </table>` }} />


        </div>
        {!props.candidateProfile &&
        <div >
        {props.isLoading === 0 && <CircularProgress size={24} />}
        <button style={{backgroundColor: "#144681", color:"white"}} type="submit" class="button" disabled={props.isLoading || (!props.isLoading && props.hasTyped !== "1")} >
          Guardar
        </button>
        <button style={{backgroundColor: "#144681", color:"white"}} onClick={props.openModal2} type="button" class="button" >
          Preview
        </button></div>}
      </form>
    </div>

  )
};

export default EmailPruebaBody;