import React from 'react';
import team from '../../../../images/sisuteam.jpg';

const CareersOffer = (props) => {
    return (
        <React.Fragment>
        <section id="careers-offer" className="backChange section--widemargin pb-0" >
            <div className="row  align-middle align-center ">
                <div className="columns small-12 medium-10 " >
                    <h2 className="fs-4 fw-6 mb-05">Creemos que hay una mejor forma de hacer software</h2>
                    <p className="fs-8 fw-4 lh-17">SISU combina estrategia, diseño y desarrollo para crear nuevos productos y servicios digitales. Como miembros de un mismo equipo, ayudamos a nuestros clientes a hacer realidad la visión del futuro digital de sus organizaciones.</p>
                    <p className="fs-8 fw-4 lh-17">En SISU creemos que hay una mejor forma de hacer software que el modelo tradicional de fábrica. Aquí trabajamos como personas, no como recursos. Creemos que los mejores proyectos son los que tienen una visión clara, un objetivo que vale la pena perseguir y la posibilidad de generar un impacto.</p>
                   
                    
                </div>

            </div>
        </section>
        <section   className="backChange section--widemargin pb-0" >


            <div className="row align-left ">
                <div className="columns small-12">
                    <div className="row align-center ">
                        <div className="columns small-12 medium-10">
                            <h2 className="fs-4 fw-6 mb-10">Nos encantan las relaciones de largo plazo</h2>
                        </div>  
                    </div>  
                </div>         
                <div className="columns small-12 medium-7 mb-10" >
                    
                    <img alt="team" src={team} />
                    
                </div>
                <div className="columns small-12 medium-4 " >
                    

                     <p className="fs-8 fw-4 lh-17">Nuestro equipo está formado por personas <a href="https://blog.sisu.mx/profile-of-our-team-members/" target="_blank">curiosas y críticas.</a> Nos encantan las relaciones de largo plazo y aprendemos todos los días para mejorar como equipo. </p>

                    <p className="fs-8 fw-4 lh-17"> Somos los que siempre preguntan ¿Por qué? y entendemos los motivos detrás de nuestro trabajo. </p>
                    <p className="fs-8 fw-4 lh-17">Valoramos la responsabilidad personal, la perseverancia, el compromiso y la dedicación al éxito.  </p>


                    
                    
                </div>

            </div>
        </section>
      
        </React.Fragment>
    );
};

export default CareersOffer;