import {
  faExternalLinkAlt,
  faMoneyBillWave,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Salary from "../../../../img/common/Salary.svg";
import Vacations from "../../../../img/common/Vacations.svg";
import TimeManagement from "../../../../img/common/TimeManagement.svg";
import HomeOffice from "../../../../img/common/HomeOffice.svg";
import HealthyWorkload from "../../../../img/common/HealthyWorkload.svg";
import Calendar from "../../../../img/common/Calendar.svg";

const CareersBenefits = (props) => {
  return (
    <section className="backChange section--widemargin">
      <div className="row align-center ">
        <div className="columns small-12 medium-10">
          <div className="row  ">
            <div className="columns small-12 mb-10">
              <h2 className="fs-4 fw-6 ">Te ofrecemos</h2>
            </div>

            <div className="benefits-list columns small-12  medium-6 mb-10">
              <img class="benefits-list__icon" src={Salary} alt="2 dollars paper" />
              <div className="benefits-list__text">
                <p className="fs-6 fw-4 mb-02">Salario competitivo</p>
                <p className="fs-8">Aprovecha tu experiencia y habilidades</p>
              </div>
            </div>
            <div className="benefits-list columns small-12  medium-6 mb-10">
              <img class="benefits-list__icon" src={Vacations} alt="Palm tree with a floating coin" />
              <div className="benefits-list__text">
                <p className="fs-6 fw-4 mb-02">Bono vacacional</p>
                <p className="fs-8">Disfruta más de los días que te tomas</p>
              </div>
            </div>
            <div className="benefits-list columns small-12  medium-6 mb-10">
              <img class="benefits-list__icon" src={TimeManagement} alt="A cup of coffee in front of a clock" />
              <div className="benefits-list__text">
                <p className="fs-6 fw-4 mb-02">Horario flexible</p>
                <p className="fs-8">Organiza tu día laboral libremente</p>
              </div>
            </div>
            <div className="benefits-list columns small-12  medium-6 mb-10">
              <img class="benefits-list__icon" src={HomeOffice} alt="Person with a laptop in front with a cup of coffee to their side" />
              <div className="benefits-list__text">
                <p className="fs-6 fw-4 mb-02">Trabajo remoto</p>
                <p className="fs-8">
                  Forma parte de SISU sin importar donde estés
                </p>
              </div>
            </div>
            <div className="benefits-list columns small-12  medium-6 mb-10">
              <img class="benefits-list__icon" src={HealthyWorkload} alt="A heart, a briefcase and a clock" />
              <div className="benefits-list__text">
                <p className="fs-6 fw-4 mb-02">Ritmo de trabajo sostenible</p>
                <p className="fs-8">
                  Apreciamos tu tiempo y vida fuera del trabajo
                </p>
              </div>
            </div>
            <div className="benefits-list columns small-12  medium-6 mb-10">
              <img class="benefits-list__icon" src={Calendar} alt="A 5 spike star with a clock inside in front of a calendar" />
              <div className="benefits-list__text">
                <p className="fs-6 fw-4 mb-02">Semanas flexibles</p>
                <p className="fs-8">
                  Toma días libres por enfermedad o compromisos personales
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CareersBenefits;
