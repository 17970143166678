import React from 'react';

const Footer = () => {
    return (

        <footer id="contact" class="backChange  threequarter-section  b-color bg-2" >
              <div class="row expanded align-middle">
                <div class="columns small-12">
                    <p className="fs-2 fw-3 mb-05">Quieres saber más?</p>
                    <p className="fs-4 fw-3">Conoce algunos de <a href="https://sisu.mx/work.html"> nuestros proyectos </a>y el impacto que han tenido.</p>


                    <p className="contact-info fw-3">SISU Technologies
                    <br />
                        <a href="mailto:contacto@sisu.mx">contacto@sisu.mx</a>
                        <br />
                        +52 (55) 6391 9555</p>

                </div>
            </div>
            
        </footer>
    );
};

export default Footer;