import firebase from 'firebase';
require("firebase/functions");

// Initialize Firebase
const configDev = {
    apiKey: "AIzaSyCL3erN2Ijpn1ros05zNz0O6foswBA9zIA",
    authDomain: "sisu-candidates.fire.com",
    databaseURL: "https://sisu-candidates.firebaseio.com",
    projectId: "sisu-candidates",
    storageBucket: "sisu-candidates.appspot.com",
    messagingSenderId: "271511405285"
};

const configProd = {
    apiKey: "AIzaSyAi7gISGY7WxS8uyQQIl7oCwWXHiQQsXI0",
    authDomain: "sisu-candidates-production.fire.com",
    databaseURL: "https://sisu-candidates-production.firebaseio.com",
    projectId: "sisu-candidates-production",
    storageBucket: "sisu-candidates-production.appspot.com",
    messagingSenderId: "894722339784"
};

const config = configProd

const fire = firebase.initializeApp(config);

const settings = {timestampsInSnapshots: true};
fire.firestore().settings(settings);

fire.functions();

export default fire