import React, { Component } from 'react';
import { default as ReactModal } from 'react-modal';
import Select from 'react-select';

import Button from '@material-ui/core/Button';
import JobPostTimer from './JobPostTimer';
import EmailPruebaBody from '../components/EmailPruebaBody'


import { CircularProgress } from 'material-ui/Progress';
import TopBar from '../components/TopBar'
import fire from '../firebase';

import moment from 'moment';
import 'moment/locale/es';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: '-15%',
        marginRight: '-50%',
        transform: 'translate(-50%, 0%)',
        width: '53%',
        height: '90%'
    }
};
var selectOptions = []

class CandidateProfile extends Component {


    INITIAL_STATE = {
        whatFailed: "",
        reasonFailed: "",
        emailPruebaBody: "",

        //////////
        name: '',
        email: '',
        phoneNumber: '',
        residence: '',
        reasonForApplication: '',
        links: [],
        selfDescription: '',
        desiredSalary: '',
        cv: '',

        pruebas: [],
        extraQuestions: [],
        //////////

        pruebaName: '',

        selectedIndex: -1,
        selectedOption: -1,
        displayOption: 'Selecciona',

        beginTimeLimit: 0,
        completionTimeLimit: 0,
        timestamp: 0,
        isLoading: false
    }

    constructor(props) {
        super(props);

        let candidateDocId = this.props.match.params.id;
        let jobpostId = this.props.match.params.jobpostid;
        let jobpost = this.props.match.params.jobpost.split('_').join(' ');

        this.state = { ...this.INITIAL_STATE, candidateDocId: candidateDocId, jobpostId: jobpostId, jobpost: jobpost };

    }
    componentDidMount() {
        let db = fire.firestore();

        var getCurrentTimestamp = fire.functions().httpsCallable('getTimeStampCallable');
        getCurrentTimestamp().then(result => {
            this.setState({ timestamp: result.data.timestamp })
        })

        db.collection('pruebas').get().then((pruebas) => {
            var index = 0
            selectOptions = []
            pruebas.forEach((prueba) => {
                selectOptions.push({ value: prueba.id, label: prueba.data().nombre, index: index, pruebaData: prueba.data() })
                index++
            })
            this.setState({})
        })

        fire.firestore().collection('jobposts').doc(this.state.jobpostId).collection('candidates').doc(this.state.candidateDocId).onSnapshot((candidate) => {

            this.setState({
                name: candidate.data().name,
                email: candidate.data().email,
                phoneNumber: candidate.data().phoneNumber,
                residence: candidate.data().residence,
                reasonForApplication: candidate.data().reasonForApplication,
                links: candidate.data().links || [""],
                selfDescription: candidate.data().selfDescription,
                desiredSalary: candidate.data().desiredSalary,
                cv: candidate.data().cv,
                pruebas: candidate.data().pruebas || [],
                extraQuestions: candidate.data().extraQuestions || []

            })
        });

    }
    openModal = () => {
        this.setState({
            modalIsOpen: true
        })
    }
    closeModal = () => {
        this.setState({
            modalIsOpen: false
        })
    }
    handleSelection = (selectedOption) => {
        this.setState({
            selectedOption: selectedOption.value, displayOption: selectedOption.label, selectedIndex: selectedOption.index,
            emailPruebaBody1: selectOptions[selectedOption.index].pruebaData.emailBody,
            emailPruebaBody2: selectOptions[selectedOption.index].pruebaData.emailBody2,
            beginTimeLimit: selectOptions[selectedOption.index].pruebaData.beginTimeLimit / 3600000,
            completionTimeLimit: selectOptions[selectedOption.index].pruebaData.completionTimeLimit / 3600000
        });
    }
    addEmailSendToRecentActivity = (timestamp) => {
        let db = fire.firestore();

        return db.collection('recentActivity').add({
            activity: "Prueba enviado",
            candidateId: this.state.candidateDocId,
            jobpost: this.state.jobpost,
            jobpostId: this.state.jobpostId,
            name: this.state.name,
            timestamp: timestamp
        })
    }

    sendPrueba = () => {
        this.setState({ isLoading: true })
        var beginTestDueDate = ""
        var completionTimeLimit = ""

        var pruebas = this.state.pruebas


        var getCurrentTimestamp = fire.functions().httpsCallable('getTimeStampCallable');
        getCurrentTimestamp().then(result => {
            beginTestDueDate = this.state.beginTimeLimit * 3600000 + result.data.timestamp
            completionTimeLimit = selectOptions[this.state.selectedIndex].pruebaData.completionTimeLimit
            var indexPrueba = pruebas.length

            pruebas.push({
                dueDateBeginTest: beginTestDueDate,
                timestamps: [result.data.timestamp],
                phase: 1,
                pruebaData: { ...selectOptions[this.state.selectedIndex].pruebaData, beginTimeLimit: this.state.beginTimeLimit * 3600000, completionTimeLimit: this.state.completionTimeLimit * 3600000 },
                magicLinkURL: `https://careers.sisu.mx/prueba-dashboard/${this.state.jobpostId}/${indexPrueba}/${this.state.candidateDocId}`,
                rechazado: false,
                totalHoursToComplete: 0,
                openedDashboard: 0
            })
            fire.firestore().collection('jobposts').doc(this.state.jobpostId).collection('candidates').doc(this.state.candidateDocId).set({
                pruebas: pruebas,
                jobpostName: this.state.jobpost,
                candidateProfileURL: this.props.location.pathname,
            }, {
                    merge: true
                }).then(() => {
                    var sendEmailPrueba = fire.functions().httpsCallable('sendEmailPrueba');
                    sendEmailPrueba({
                        MagicLinkURL: pruebas[pruebas.length - 1].magicLinkURL,
                        emailBody: this.state.emailPruebaBody1,
                        emailBody2: this.state.emailPruebaBody2,
                        email: this.state.email,
                        name: this.state.name,
                        jobTitle: this.state.jobpost,
                        pruebaName: selectOptions[this.state.selectedIndex].pruebaData.nombre,
                        beginTestDueDate: moment(beginTestDueDate, "x").locale('es').format("DD MMM "),
                        beginTestDueDateTime: moment(beginTestDueDate, "x").locale('es').format("HH:mm")
                    }).then((res) => {
                        this.addEmailSendToRecentActivity(result.data.timestamp)
                    }).catch(error => {
                        this.setState({ isLoading: false })
                    })
                    this.closeModal()
                    this.setState({ isLoading: false })
                    window.alert("El correo electrónico ha sido enviado con éxito.")
                }).catch(error => {
                    window.alert(this.state.emailPruebaBody1 + this.state.emailPruebaBody2)
                    this.setState({ isLoading: false })
                })
        }).catch(error => {
            this.setState({ isLoading: false })
        })
    }

    handlePruebaInputChange1 = (e) => {
        selectOptions[this.state.selectedIndex].pruebaData.emailBody = e.target.value
        this.setState({ emailPruebaBody1: e.target.value })
    }
    handlePruebaInputChange2 = (e) => {
        selectOptions[this.state.selectedIndex].pruebaData.emailBody2 = e.target.value
        this.setState({ emailPruebaBody2: e.target.value })
    }

    render() {

        return (
            <div id="admin">

                <TopBar />

                <section id="page-title">
                    <div class="row">
                        <div class="columns medium-12 small-12  ">

                            <h1>CANDIDATO</h1>
                            <h2>{this.state.name}</h2>
                            <h3>Aplicó a - {this.state.jobpost}</h3>
                        </div>
                    </div>
                </section>
                <section id="candidato" class="">
                    <div class="row">
                        <div class="columns small-12  ">




                            <div class="row">
                                <div class="columns large-6 medium-12 small-12  ">
                                    <p class="col-title">Perfil de candidato</p>

                                    <div class="grid-t">
                                        <div class="section-title">Información personal</div>
                                    </div>
                                    <div class="grid-c">
                                        <div >Nombre completo</div>
                                        <div >{this.state.name}</div>
                                        <div >Lugar de residencia</div>
                                        <div>{this.state.residence}<br />
                                        </div>

                                        <div>¿Por qué quieres trabajar en SISU?</div>
                                        <div>{this.state.reasonForApplication}</div>
                                        <div>Sobre el candidato</div>
                                        <div>{this.state.selfDescription}</div>

                                    </div>
                                    <div class=" grid-t">
                                        <div class="section-title">Contacto</div>
                                    </div>
                                    <div class=" grid-c">

                                        <div>Correo electrónico</div>
                                        <div><a href="mailto:">{this.state.email}</a></div>
                                        <div>Teléfono</div>
                                        <div><a href="tel:">{this.state.phoneNumber}</a></div>
                                    </div>
                                    <div class=" grid-t">
                                        <div class="section-title">Académico y laboral</div>
                                    </div>
                                    <div class=" grid-c">
                                        <div>Curriculum Vitae</div>
                                        <div><a target="_blank" rel="noopener noreferrer" href={this.state.cv}>Descargar CV</a></div>
                                        <div>Sueldo deseado</div>
                                        <div>{this.state.desiredSalary}</div>
                                        <div>URL's de candidato</div>

                                        <div class="col ">
                                            {this.state.links.map((link, index) => {
                                                return (
                                                    <div><a target="_blank" rel="noopener noreferrer" href={link}>{link}</a></div>
                                                )

                                            })}
                                        </div>
                                    </div>

                                    {this.state.extraQuestions.length > 0 && <div class=" grid-t">
                                        <div class="section-title">Preguntas adicionales</div>
                                    </div>}
                                    <div class=" grid-c">
                                            {this.state.extraQuestions.map((question, index) => {
                                                return (
                                                    <>
                                                        <div>{question.question}</div>
                                                        <div>{question.answer || ""}</div>
                                                    </>
                                                )

                                            })}
                                    </div>

                                </div>

                                <div class="columns large-6 medium-12 small-12  ">
                                    <p class="col-title">Proceso interno</p>
                                    {this.state.pruebas.map((prueba, index) => {
                                        return (
                                            <div class="timeline">
                                                <div class="section-title"> Prueba {index + 1} - {prueba.pruebaData.nombre}</div>
                                                {prueba.phase === 1 && <div>

                                                    <div class="section-title"> Prueba pre-inicio </div>
                                                    <div class=""> {"La prueba se le envió el " + moment(prueba.timestamps[0], "x").locale('es').format("DD MMM ") + " a las " + moment(prueba.timestamps[0], "x").locale('es').format("HH:mm")}</div>

                                                    {prueba.openedDashboard > 0 ? <div class=""> {"El usuario abrió el dashboard el " + moment(prueba.openedDashboard, "x").locale('es').format("DD MMM ") + " a las " + moment(prueba.openedDashboard, "x").locale('es').format("HH:mm")}</div> : <div class="">El usuario no abrió el dashboard </div>}

                                                    <div class=""> El usuario no ha iniciado la prueba - tiempo restante: </div>
                                                    {prueba.dueDateBeginTest > this.state.timestamp && <JobPostTimer dueDate={prueba.dueDateBeginTest} />}
                                                    {prueba.dueDateBeginTest <= this.state.timestamp && <div class=""> Rechazado por tiempo </div>}
                                                    {prueba.dueDateBeginTest <= this.state.timestamp && <div class=""> {"El usuario rechazó por tiempo a las " + moment(prueba.dueDateBeginTest, "x").locale('es').format("DD MMM ") + " a las " + moment(prueba.dueDateBeginTest, "x").locale('es').format("HH:mm")} </div>}
                                                </div>

                                                }
                                                {prueba.phase === 2 && <div>
                                                    <div class="section-title"> Prueba en casa: </div>
                                                    <div class=""> {"La prueba se le envió el " + moment(prueba.timestamps[0], "x").locale('es').format("DD MMM ") + " a las " + moment(prueba.timestamps[0], "x").locale('es').format("HH:mm")}</div>
                                                    <div class=""> {"La prueba la descargó el " + moment(prueba.timestamps[1], "x").locale('es').format("DD MMM ") + " a las " + moment(prueba.timestamps[1], "x").locale('es').format("HH:mm")}</div>
                                                    <div class=""> El usuario está haciendo la prueba - tiempo restante: </div>
                                                    {prueba.dueDateCompleteTest > this.state.timestamp && <JobPostTimer dueDate={prueba.dueDateCompleteTest} />}
                                                    {prueba.dueDateCompleteTest <= this.state.timestamp && <div class=""> Rechazado por tiempo </div>}
                                                    {prueba.dueDateCompleteTest <= this.state.timestamp && <div class=""> {"El usuario rechazó por tiempo a las " + moment(prueba.dueDateCompleteTest, "x").locale('es').format("DD MMM ") + " a las " + moment(prueba.dueDateCompleteTest, "x").locale('es').format("HH:mm")} </div>}
                                                </div>
                                                }
                                                {prueba.phase === 3 && <div><div class="section-title"> Prueba enviada: </div>
                                                    <div class=""> El usuario termino la prueba </div>
                                                    <div class="section-title"> Bajar prueba: <a href={prueba.homework}>{prueba.homework}</a> </div>
                                                    <div class=""> Horas de trabajo proporcionado por el candidato: {prueba.totalHoursToComplete} </div>
                                                    <div class=""> {"La prueba se le envió el " + moment(prueba.timestamps[0], "x").locale('es').format("DD MMM ") + " a las " + moment(prueba.timestamps[0], "x").locale('es').format("HH:mm")}</div>
                                                    <div class=""> {"La prueba la descargó el " + moment(prueba.timestamps[1], "x").locale('es').format("DD MMM ") + " a las " + moment(prueba.timestamps[1], "x").locale('es').format("HH:mm")}</div>
                                                    <div class=""> {"El resultado lo envió el " + moment(prueba.timestamps[2], "x").locale('es').format("DD MMM ") + " a las " + moment(prueba.timestamps[2], "x").locale('es').format("HH:mm")}</div>
                                                </div>
                                                }
                                                ___________________________________________________________
                                                <br /><br />
                                            </div>
                                        )

                                    })}

                                    <p class="col-title"></p>
                                    <Button onClick={this.openModal} style={{ backgroundColor: "#144681", color: "white" }} class="button">
                                        Mandar Prueba
                                    </Button>
                                </div>

                            </div>
                            <div class="row align-right">
                                <div class="columns shrink">
                                    <Button style={{ backgroundColor: "#144681", color: "white" }} onClick={() => this.props.history.push({ pathname: `/editar-candidato/${this.state.candidateDocId}/${this.state.jobpostId}/${this.state.jobpost}` })} class="button">
                                        Editar Perfil
                                    </Button>

                                </div>
                            </div>


                            <ReactModal
                                isOpen={this.state.modalIsOpen}
                                onRequestClose={this.closeModal}
                                style={customStyles}
                            >

                                <div>
                                    <div class="section-title">Seleccionar prueba</div>
                                    <Select
                                        value={this.state.selectedOption}
                                        onChange={this.handleSelection}
                                        options={selectOptions}
                                        placeholder={this.state.displayOption}
                                    >
                                    </Select>
                                    <h3>Correo</h3>

                                    {this.state.selectedIndex > -1 && <EmailPruebaBody
                                        emailPruebaBody1={selectOptions[this.state.selectedIndex].pruebaData.emailBody}
                                        emailPruebaBody2={selectOptions[this.state.selectedIndex].pruebaData.emailBody2 || ""}
                                        displayOption={this.state.displayOption}
                                        writtenDate={moment(this.state.beginTimeLimit * 3600000 + this.state.timestamp, "x").locale('es').format("DD MMM ") + " a las " + moment(this.state.beginTimeLimit * 3600000 + this.state.timestamp, "x").locale('es').format("HH:mm")}
                                        handlePruebaInputChange1={this.handlePruebaInputChange1}
                                        handlePruebaInputChange2={this.handlePruebaInputChange2}
                                        onFocus={() => { }}
                                        candidateProfile={true}
                                    />}

                                    <div >Tiempo limite para iniciar (horas)</div>
                                    <input name="beginTimeLimit" placeholder="horas" type="number"
                                        value={this.state.beginTimeLimit} disabled={this.state.selectedIndex === -1}
                                        onChange={(e) => this.setState({ beginTimeLimit: e.target.value })}
                                    ></input>
                                    <div >Tiempo limite para entregar (horas)</div>
                                    <input name="completionTimeLimit" placeholder="horas" type="number"
                                        value={this.state.completionTimeLimit} disabled={this.state.selectedIndex === -1}
                                        onChange={(e) => this.setState({ completionTimeLimit: e.target.value })}
                                    ></input>

                                    <button style={{ backgroundColor: "#144681", color: "white" }} id="magicModalBtn" onClick={this.sendPrueba} class="button" disabled={this.state.selectedIndex === -1}>Mandar</button>
                                    {this.state.isLoading && <CircularProgress size={24} />}
                                </div>
                            </ReactModal>
                            <ReactModal
                                isOpen={this.state.modalIsOpen2}
                                onRequestClose={this.closeModal2}
                                style={customStyles}
                            >


                            </ReactModal>



                        </div>
                    </div>
                </section>

            </div>
        );
    }
}

export default CandidateProfile;