import React from 'react';

const TopBar = (props) => {

    return (


        <div className="sticky-container">
 
            <div className="top-bar sticky is-at-top white " id="menu-1" style={{ width: '100%' }} >
                <div className="top-bar-title">
                    <a href="https://sisu.mx/index.html"><div className="logo"></div></a>
                </div>
                <span className="responsive-menu-icon"  >
                    <button className="menu-icon float-right" type="button" onClick={props.toggleResponsiveClass}></button>
                </span>

                <div id="responsive-menu" className={`${props.responsiveClass}`}>
                    <div className="top-bar-right">
                        <ul className="menu" id="menu">
                            <li><a href="https://sisu.mx/index.html">Home</a></li>
                            <li><a href="https://sisu.mx/services.html">Services</a></li>
                            <li><a href="/" className="active">Careers</a></li>
                            <li><a href="https://sisu.mx/work.html">Work</a></li>
                            <li><a href="https://sisu.mx/contact.html">Contact</a></li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>




    );
};

export default TopBar;