import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faExternalLinkSquareAlt, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const JobPostList = (props) => {
  if (props.vacantes) {
    return (
      <div className="row  ">
        {props.vacantes.map((item, index) => (
          <div className="columns small-12  ">
            <a href={"/jobpost/" + item.id + "/detail"}>
              <div className="job-post ">
                <div className="job-post__header">
                  <p className="fs-6  fw-4 mb-05">{item.nombre}</p>
                <p className="job-post__description lh-16  mb-00">{item.simpleDescription}</p>
                </div>
                  <FontAwesomeIcon
                    className="title-icon icon"
                    icon={faExternalLinkAlt}
                  />

              </div>
            </a>
            <hr />
          </div>
        ))}
      </div>
    );
  }

  return <div>Cargando vacantes...</div>;
};

export default JobPostList;

/*
              <Card key={index}>
                <CardContent>
                  <Typography variant="headline" component="h2">{item.name}</Typography>
                  <Typography component="p">{item.description}</Typography>
                </CardContent>
                <CardActions>
                  <Link to={"/jobpost/" + item.id + "/detail"}>
                    <Button variant="raised" color="primary">Ver vacante</Button>
                  </Link>
                </CardActions>
              </Card>
*/
