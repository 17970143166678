import React, { Component } from 'react';


import Button from '@material-ui/core/Button';

import TopBar from '../../components/TopBar'
import fire from '../../firebase';

import ReactTable from 'react-table'
import "react-table/react-table.css";

import DeleteIcon from '@material-ui/icons/Delete'

var data = []
var unsubscribe = ''

class Pruebas extends Component {

    INITIAL_STATE = { nombre: '', descripción: '' }

    constructor(props) {
        super(props);
        this.state = { ...this.INITIAL_STATE };

    }
    componentWillUnmount() {
        //unsubscribe from query snapshot listener
        unsubscribe();
    }
    componentDidMount() {
        let db = fire.firestore();

        unsubscribe = db.collection('pruebas').onSnapshot((querySnapshot) => {
            data = []
            querySnapshot.forEach((doc) => {
                data.push({
                    nombre: <a href={doc.data().challenge}>{doc.data().nombre}</a>,
                    descripción: doc.data().descripción,
                    Editar: <Button style={{ color: 'blue' }} onClick={() => this.props.history.push({
                        pathname: '/editar-prueba',
                        pruebaId: doc.id,
                        nombre: doc.data().nombre,
                        descripción: doc.data().descripción,
                        fullDescriptionHTML: doc.data().fullDescriptionHTML || "",
                        challengeFileName: doc.data().challengeFileName,
                        challengeURL: doc.data().challenge,
                        beginTimeLimit: doc.data().beginTimeLimit,
                        completionTimeLimit: doc.data().completionTimeLimit,

                    })}>editar</Button>,
                    Borrar: <Button onClick={() => {
                        if (window.confirm("¿Estás seguro de que deseas eliminar este prueba?")) {
                            this.deletePrueba(doc.id, doc.data().nombre, doc.data().challengeFileName)
                        }
                    }}><DeleteIcon /></Button>,
                });
            });
            this.setState({ data: data })
        });
    }

    deletePrueba(pruebaId, nombre, challengeFileName) {
        let db = fire.firestore();

        db.collection("pruebas").doc(pruebaId).delete().then(() => {
            console.log("Document successfully deleted!");
            window.alert("Prueba eliminado con éxito!")

            // Delete the challenge file from storage
            var deleteRef = fire.storage().ref().child(`ejercicios-candidatos/${nombre}_${challengeFileName}`);
            deleteRef.delete().then(() => {
                //deleted
            }).catch( error => {
                console.log(error.message)
            });

        }).catch((error) => {
            console.error("Error removing document: ", error);
        });

    }

    render() {

        const columns = [{
            Header: 'Nombre',
            accessor: 'nombre'
        }, {
            Header: 'Descripción',
            accessor: 'descripción'
        }, {
            Header: 'Editar',
            accessor: 'Editar'
        }, {
            Header: 'Borrar',
            accessor: 'Borrar'
        }
        ]

        return (
            <div id="admin">

                <TopBar />

                <section id="page-title">
                    <div class="row">
                        <div class="columns medium-12  ">
                            <h1>Pruebas</h1>
                        </div>
                    </div>
                </section>
                <section id="admin-feed" class="">
                    <div class="row align-center align-middle ">
                        <div class="columns medium-12  ">

                            <ReactTable
                                data={data}
                                columns={columns}
                                defaultPageSize={12}
                                pageSizeOptions={[12, 16]}
                            />

                        </div>
                    </div>
                </section>

                <div class="row align-right">
                    <div class="columns shrink">
                        <Button style={{backgroundColor: "#144681", color:"white"}} onClick={() => this.props.history.push({ pathname: '/nueva-prueba' })} class="button">
                            Agregar nueva prueba
                        </Button>
                    </div>
                </div>

            </div>
        );
    }
}

export default Pruebas;