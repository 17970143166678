import React from 'react';

import Close from '@material-ui/icons/Close';
import Add from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Select from 'react-select';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { CircularProgress } from 'material-ui/Progress';

const VacanteForm = (props) => {
    return (
        <section id="vacante-edit" class="">
            <form onSubmit={props.handleSubmit}>
                <div class="row">
                    <div class="columns medium-12  ">
                        <div class="grid-c">
                            <div >Vacante</div>

                            <div >
                                <input name="nombre" type="text" placeholder="Vacante"
                                    required={true}
                                    onChange={props.handleFormInputChange}
                                    value={props.nombre} />
                            </div>

                            <div>Color de etiqueta</div>
                            <div>
                                <button name="labelColor" value="blue" onClick={(e) => {
                                    e.preventDefault()
                                    props.handleFormInputChange(e)
                                }}
                                    style={{ width: 112, height: 18, backgroundColor: 'blue', marginRight: 12, marginTop: 6, borderRadius: 2, border: props.labelColor === 'blue' ? '2px solid black' : '' }}></button>
                                <button name="labelColor" value="green" onClick={(e) => {
                                    e.preventDefault()
                                    props.handleFormInputChange(e)
                                }}
                                    style={{ width: 112, height: 18, backgroundColor: 'green', marginRight: 12, marginTop: 10, borderRadius: 2, border: props.labelColor === 'green' ? '2px solid black' : '' }}></button>
                                <button name="labelColor" value="yellow" onClick={(e) => {
                                    e.preventDefault()
                                    props.handleFormInputChange(e)
                                }}
                                    style={{ width: 112, height: 18, backgroundColor: 'yellow', marginRight: 12, marginTop: 10, borderRadius: 2, border: props.labelColor === 'yellow' ? '2px solid black' : '' }}></button>
                                <button name="labelColor" value="orange" onClick={(e) => {
                                    e.preventDefault()
                                    props.handleFormInputChange(e)
                                }}
                                    style={{ width: 112, height: 18, backgroundColor: 'orange', marginRight: 12, marginTop: 10, borderRadius: 2, border: props.labelColor === 'orange' ? '2px solid black' : '' }}></button>
                                <button name="labelColor" value="purple" onClick={(e) => {
                                    e.preventDefault()
                                    props.handleFormInputChange(e)
                                }}
                                    style={{ width: 112, height: 18, backgroundColor: 'purple', marginRight: 12, marginTop: 10, borderRadius: 2, border: props.labelColor === 'purple' ? '2px solid black' : '' }}></button>
                                <button name="labelColor" value="red" onClick={(e) => {
                                    e.preventDefault()
                                    props.handleFormInputChange(e)
                                }}
                                    style={{ width: 112, height: 18, backgroundColor: 'red', marginRight: 12, marginTop: 10, borderRadius: 2, border: props.labelColor === 'red' ? '2px solid black' : '' }}></button>
                                <button name="labelColor" value="pink" onClick={(e) => {
                                    e.preventDefault()
                                    props.handleFormInputChange(e)
                                }}
                                    style={{ width: 112, height: 18, backgroundColor: '#ff5ea1', marginRight: 12, marginTop: 10, borderRadius: 2, border: props.labelColor === 'pink' ? '2px solid black' : '' }}></button>
                            </div>

                            <div style={{ marginTop: 32 }}>Descripción básica para mostrar en la primera pantalla</div>
                            <div style={{ marginTop: 32 }}>
                                <textarea name="simpleDescription" rows="3" placeholder="Descripción Básica"
                                    required="true"
                                    onChange={props.handleFormInputChange}
                                    defaultValue={props.simpleDescription}
                                ></textarea>
                            </div>

                        </div>

                    </div>
                </div>


                <div style={{ marginTop: 22, }} class="row align-right">
                    <div>Descripción</div>
                    <div style={{ maxWidth: 908, alignItems: 'flex-end' }} class="columns shrink  ">

                        <Editor
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            placeholder="Escribir descripción..."
                            editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)", padding: 8, marginBottom: 10 }}
                            onEditorStateChange={props.onEditorStateChange}
                            defaultEditorState={props.defaultEditorState}
                        />
                    </div>
                </div>
                <div class="row align-right" style={{ alignItems: 'center'}}>
                <div style={{fontSize: 22, marginTop: 16, marginBottom: 8, marginRight: 12}}>Preguntas Extras</div>
                {props.extraQuestions.length === 0 && <Button style={{marginRight: 18, marginTop:12}} onClick={props.addQuestion} className="button-input  button  button--text-thick button--border-medium button--text-upper button--size-s " type="" ><Add /></Button>}

                </div>
                <div style={{ marginTop: 22, }} class="row align-right">
                    

                    {props.extraQuestions.map((question, index) => {
                        return (
                            <div className="medium-10 cell " style={{width: '75%'}}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <input type="text"
                                        placeholder="Pregunta"
                                        value={question.question}
                                        required={true}
                                        onChange={(e) => props.handleFormQuestionInputChange(e, index)}
                                    />
                                    {index === props.extraQuestions.length - 1 && <Button onClick={props.addQuestion} className="button-input  button  button--text-thick button--border-medium button--text-upper button--size-s " type="" ><Add /></Button>}
                                    {<Button onClick={() => props.removeQuestion(index)} className="button-input button  button--text-thick button--border-medium button--text-upper button--size-s  " type="" ><Close /></Button>}
                                </div>

                                <Select
                                    value={question.optionRequired}
                                    onChange={(selectedOption) => props.handleOptionRequired(selectedOption, index)}
                                    options={[{ value: true, label: "Requerido" }, { value: false, label: "Opcional" }]}
                                    placeholder={question.optionRequired ? "Requerido" : "Opcional"}
                                >
                                </Select>

                                <Select
                                    value={question.optionTextInput}
                                    onChange={(selectedOption) => props.handleOptionTextInput(selectedOption, index)}
                                    options={[{ value: true, label: "Text Input" }, { value: false, label: "Text Area" }]}
                                    placeholder={question.optionTextInput ? "Text Input" : "Text Area"}
                                >
                                </Select>
<br/>                            </div>
                        )
                    })}
                </div>


                <div></div>
                

                <div class="row align-right">
                    <div class="columns shrink" style={{ display: "flex", flexDirection: "row", }}>
                        <a href={'/vacantes'} disabled={props.isLoading} style={{ pointerEvents: props.isLoading ? "none" : "" }} class="button" style={{ backgroundColor: "#144681", color: "white" }}>Cancelar </a>
                        <button type="submit" disabled={props.isLoading} class="button" style={{ backgroundColor: "#144681", color: "white" }}>Guardar </button>
                        {props.isLoading && <CircularProgress size={24} />}
                    </div>
                </div>


            </form>
        </section>

    )
};

export default VacanteForm;