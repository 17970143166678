import React from 'react';
import {default as ReactModal} from 'react-modal';
import { customModalStyles } from "../../styles/styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'


const Modal = (props) => {
    return (
        <ReactModal
        isOpen={props.modalIsOpen}
        onRequestClose={props.closeModal}
        className="small-modal"
    >

        <div>
            
                <p className="fs-7 modal-title">{props.modalHeaderText}</p>
                <p className=" modal-content">{props.modalBodyText} </p>
                
                <a id="magicModalBtn" onClick={props.closeModal}  className="expanded button button--shikoba button--text-thick button--border-medium button--text-upper button--size-l">
                  
                     <FontAwesomeIcon className="button__icon icon" icon={faCheckCircle} />

                    <span>Aceptar</span>
                </a>
                
           
        </div>
    </ReactModal>
    );
};

export default Modal;