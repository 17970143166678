import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons'

const JobDescription = (props) => {
    return (
        <div>
            
            <section id="job-title" className="backChange job-apply-title-section   b-color bg-1" >
                
                <div className="row  align-middle align-center ">
                    <div className="columns small-12  text-center ">
                        <h1 className="fs-3 fw-5">{props.nombre}</h1>
                        <h2 className="fs-6 fw-5">Vacante</h2>
                    </div>

                </div>
            </section>

            <section id="job-description" className="backChange  ">
                <div className=" row  align-middle align-center  ">
                    <div className="columns small-12 medium-8">
                        <div className="job-description">
                            <div dangerouslySetInnerHTML={{ __html: props.description }} />
                        </div>
                        <a href={`/jobpost/apply/${props.jobpostDocId}`} className="expanded button button--shikoba button--text-thick button--border-medium button--text-upper button--size-l">

                            <FontAwesomeIcon className="button__icon icon" icon={faUserPlus} />

                            <span>Aplicar a vacante</span>
                        </a>

                    </div>
                </div>
            </section>

        </div>
    );
};

export default JobDescription;