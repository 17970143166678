import React, { Component } from 'react';

import Footer from '../../components/Footer';
import TopBar from '../../components/TopBar';
import { JobDescription } from "./components";
import Modal from "../../components/Modal";

import fire from "../../../firebase";

import { Controller, Scene } from 'react-scrollmagic';
import styled from 'styled-components';

const ClassToggleStyled = styled.div``;

class JobPostDetail extends Component {

    INITIAL_STATE = {
        vacante: {},
        modalIsOpen: false,
        modalHeaderText: '',
        modalBodyText: '',
        vacante: { jobpostDocId: this.props.match.params.id },

        isLoading: true,
        activated: true,
        responsiveClass: ""
    };

    constructor(props) {
        super(props);
        this.state = this.INITIAL_STATE;
    }

    componentDidMount() {
        fire.firestore().collection('jobposts').doc(this.state.vacante.jobpostDocId).onSnapshot((vacante) => {
            this.setState({ activated: vacante.data().activado, vacante: { ...vacante.data(), jobpostDocId: this.state.vacante.jobpostDocId }, isLoading: false });
        });
    }

    openModal = (modalHeader, modalBody) => {
        this.setState({
            modalHeaderText: modalHeader,
            modalBodyText: modalBody,
            modalIsOpen: true
        })
    }
    closeModal = () => {
        this.setState({
            modalHeaderText: '',
            modalBodyText: '',
            modalIsOpen: false
        })
    }

    render() {
        if (this.state.isLoading)
            return null
        else if (!this.state.activated)
            return <h1>Esta vacante está deshabilitada</h1>
        else {
            return (

                <ClassToggleStyled>
                    <div id="trigger" />
                    <Controller>

                        <Scene duration="1" classToggle={['.top-bar', 'is-anchored']} reverse={true} triggerHook={0} triggerElement={'#trigger'} indicators={false} >
                            {(progress, event) => (<div></div>)}
                        </Scene>
                        <Scene duration="0" offset="1" classToggle={['.top-bar', 'is-stuck']} reverse={true} triggerHook={0} triggerElement={'#trigger'} indicators={false} >
                            <div></div>
                        </Scene>
                        <Scene duration="0" classToggle={['.top-bar', 'no-white']} reverse={true} triggerHook={0} triggerElement={'#job-description'} indicators={false} pin>
                            <div></div>
                        </Scene>

                    </Controller>
                    <div>

                        <TopBar toggleResponsiveClass={() => this.setState({ responsiveClass: this.state.responsiveClass === "" ? "d-block" : "" })} responsiveClass={this.state.responsiveClass || ""} />

                        <JobDescription
                            nombre={this.state.vacante.nombre}
                            jobpostDocId={this.state.vacante.jobpostDocId}
                            description={this.state.vacante.fullDescriptionHTML} />

                        <Modal modalIsOpen={this.state.modalIsOpen}
                            closeModal={this.closeModal}
                            modalHeaderText={this.state.modalHeaderText}
                            modalBodyText={this.state.modalBodyText} />

                        <Footer />

                    </div>
                </ClassToggleStyled>
            )
        }
    }
}

export default JobPostDetail;