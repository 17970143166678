import React, { Component } from 'react';

import fire from '../../firebase';

import TopBar from '../../components/TopBar'
import { PruebaForm } from "./components";
import { convertToRaw, ContentState, convertFromHTML, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class NewPrueba extends Component {

    INITIAL_STATE = {
        nombre: '',
        descripción: '',
        beginTimeLimit: 0,
        completionTimeLimit: 0,
        challenge: '',
        isSubmittingForm: false,

        challengeUploaded: false,
        fullDescriptionHTML: '',
        editorState: undefined,
    }

    constructor(props) {
        super(props);
        this.state = { ...this.INITIAL_STATE };
    }

    handleFormInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }
    handleOnChangeChallenge = (event) => {
        this.setState({
            challenge: event.target.files[0],
            challengeFileName: event.target.files[0].name,
            challengeUploaded: true
        })
    }
    onEditorStateChange = (editorState) => {
        this.setState({
            editorState
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();

        if (this.state.editorState) {
            if (this.state.beginTimeLimit <= 0 || this.state.completionTimeLimit <= 0) {
                window.alert("Las horas deben ser mayores que 0")
                this.setState({ isLoading: false })
            }
            else if (this.state.challengeUploaded) {
                //isSubmitting form is used to disable the submit button while submitting
                this.setState({ isSubmittingForm: true })

                let db = fire.firestore();

                let storage = fire.storage().ref();
                let challengeStorage = storage.child(`ejercicios-candidatos/${this.state.nombre}_${this.state.challenge.name}`);

                challengeStorage.put(this.state.challenge).then(() => {

                    return challengeStorage.getDownloadURL()
                }).then(downloadURL => {

                    let pruebaRef = {
                        nombre: this.state.nombre,
                        descripción: this.state.descripción,
                        fullDescriptionHTML: "" + draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())), beginTimeLimit: this.state.beginTimeLimit * 3600000, //convert to miliseconds
                        completionTimeLimit: this.state.completionTimeLimit * 3600000, //convert to miliseconds
                        challenge: downloadURL,
                        challengeFileName: this.state.challengeFileName,
                        emailBody: "Te hemos seleccionado para continuar en el proceso de reclutamiento. Como siguiente paso te estamos enviando una prueba a resolver en casa. Deberás seguir este link para ver las instrucciones y descargarla:",
                        emailBody2: "Agradecemos mucho tu participación y nos encantará ver tu solución. Cualquier duda o problema puedes contactarnos. \nSaludos,"
                    }

                    return db.collection('pruebas').add(pruebaRef)
                }).then(docRef => {

                    this.props.history.push('/pruebas')
                }).catch(error => {

                    console.log(error.message);
                });

            }
            else {
                window.alert('Necesitas subir una prueba.')
            }
        }
        else {
            window.alert("La vacante necesita una descripción")
            this.setState({ isLoading: false })
        }
    }

    render() {
        return (
            <div id="admin">
                <TopBar />

                <PruebaForm headerText={'CREAR PRUEBA'}
                    handleSubmit={this.handleSubmit}
                    handleFormInputChange={this.handleFormInputChange}
                    handleOnChangeChallenge={this.handleOnChangeChallenge}
                    nombre={this.state.nombre}
                    descripción={this.state.descripción}
                    onEditorStateChange={this.onEditorStateChange}
                    defaultEditorState={undefined}
                    challengeFileName={this.state.challengeFileName}
                    isSubmittingForm={this.state.isSubmittingForm}
                    beginTimeLimit={this.state.beginTimeLimit || 0}
                    completionTimeLimit={this.state.completionTimeLimit || 0} />

            </div>
        );
    }
}

export default NewPrueba;