import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './styles.scss';

import { Route, withRouter } from 'react-router';
import { BrowserRouter, Switch } from 'react-router-dom';

//import FirstPage from './containers/FirstPage';
//import JobPostDetail from './containers/JobPostDetail/JobPostDetail';
//import JobPostForm from './containers/JobPostForm/JobPostForm';
//import PruebaDashboard from './containers/PruebaDashboard/PruebaDashboard';
import Routes from './Routes';



class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
        }
    }

    render() {
        return this.props.children
    }
}
export default withRouter(ScrollToTop)

ReactDOM.render(
    <BrowserRouter>

        <Switch>
            <ScrollToTop>

                <Routes />
                
            </ScrollToTop>
        </Switch>

    </BrowserRouter>
    , document.getElementById('root')
);
