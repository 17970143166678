import React, { Component } from 'react';
import { Redirect } from 'react-router';
import Footer from '../../components/Footer';
import TopBar from '../../components/TopBar';

import { ApplyForm, PrivacyPolicyModal } from "./components";

import Modal from "../../components/Modal";

import fire from '../../../firebase';
import { Controller, Scene } from 'react-scrollmagic';
import styled from 'styled-components';

const ClassToggleStylede = styled.div``;

class JobPostForm extends Component {


    INITIAL_STATE = {
        closedAppliedModal: false,

        modalIsOpen: false,
        privacyModalIsOpen: false,
        modalHeaderText: '',
        modalBodyText: '',
        fileModalShow: false,
        fileStatus: 0,

        form: {
            name: '',
            email: '',
            phoneNumber: '',
            residence: '',

            reasonForApplication: '',
            desiredSalary: '',
            selfDescription: '',

            links: [""],

            cv: "",

            acceptPrivacyTerms: false,

            applicationCompleted: false
        }, jobpost: {
            id: '',
            name: '',
            labelColor: '',
            extraQuestions: []
        },
        enviandoFormulario: false,

        fileName: 'ningún archivo elegido',
        files: [],
        file: '',

        hasUploadedCV: false,

        isLoading: true,
        activado: true,
        responsiveClass: ""

    };

    constructor(props) {
        super(props);
        this.state = { ...this.INITIAL_STATE };
    }

    componentDidMount() {
        let db = fire.firestore();
        let jobpostDocId = this.props.match.params.id;

        db.collection('jobposts').doc(jobpostDocId).onSnapshot((vacante) => {
            this.setState({
                ...this.state,
                activated: vacante.data().activado,
                jobpost: { ...this.state.jobpost, id: jobpostDocId, name: vacante.data().nombre, labelColor: vacante.data().labelColor, extraQuestions: vacante.data().extraQuestions || [] },
                isLoading: false
            });
            //window.alert(JSON.stringify(vacante.data().extraQuestions))
        });
    }

    handleFormInputChange = (event) => {
        this.setState({ form: { ...this.state.form, [event.target.name]: event.target.value } });
    }
    handleFormInputQuestionChange = (event, index) => {
        let extraCopy = this.state.jobpost.extraQuestions
        extraCopy[index].answer = event.target.value
        this.setState({ jobpost: { ...this.state.jobpost, extraQuestions: extraCopy } });
    }

    doesEmailExist = async () => {
        let jobpostDocId = this.props.match.params.id;
        const db = fire.firestore();

        const emails = await db.collection('jobposts').doc(jobpostDocId).collection('candidates').where('email', '==', this.state.form.email).get()

        if (emails.size === 0)
            return true
        else {
            this.openModal('Hubo un error al enviar tu aplicación', '¿Ya has aplicado antes a esta vacante? El correo electrónico que ingresaste ya está registrado en nuestro sistema. Recuerda que solo puedes aplicar una vez por vacante.')
            this.setState({ enviandoFormulario: false }, () => { document.body.style.overflow = "scroll" })
            return false
        }
    }

    createUserAndUploadToFirestore = async (timestamp) => {
        let candidateDocRef = await fire.firestore().collection('jobposts').doc(this.state.jobpost.id).collection('candidates').add({
            name: this.state.form.name,
            email: this.state.form.email,
            phoneNumber: this.state.form.phoneNumber,
            residence: this.state.form.residence,
            reasonForApplication: this.state.form.reasonForApplication,
            links: this.state.form.links,
            selfDescription: this.state.form.selfDescription,
            desiredSalary: this.state.form.desiredSalary,
            appliedTimestamp: timestamp,
            extraQuestions: this.state.jobpost.extraQuestions || [],

            cv: '',
            cvFilename: this.state.form.name + "_" + this.state.form.cv[0].name,
            pruebas: []
        })

        let fileURL = await this.uploadCVToFirebaseStorage()

        //add the download url to the already created candidate document
        await candidateDocRef.set({ cv: fileURL }, { merge: true })
        return { id: candidateDocRef.id, cvURL: fileURL }
    }
    getCurrentServerTimestamp = async () => {
        var getCurrentTimestamp = fire.functions().httpsCallable('getTimeStampCallable');
        let result = await getCurrentTimestamp()
        return result.data.timestamp
    }

    uploadCVToFirebaseStorage = async () => {
        let cv = this.state.form.cv[0];

        let cvStoragePath = `cv/${this.state.form.name}_${cv.name}`
        const cvRef = fire.storage().ref().child(cvStoragePath)

        let fileSnapshot = await cvRef.put(cv)
        return await fileSnapshot.ref.getDownloadURL()
    }
    uploadCVToFirebaseStorageWithProgress = async () => {
        let cv = this.state.form.cv[0];

        let cvStoragePath = `cv/${this.state.form.name}_${cv.name}`
        const cvRef = fire.storage().ref().child(cvStoragePath)
        let uploadCVTask = cvRef.put(cv)

        return new Promise((resolve, reject) => {
            return uploadCVTask.on('state_changed',
                (snapshot) => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    const fileStatus = { name: "CV", state: progress };
                    this.setState({ fileStatus, fileModalShow: true, enviandoFormulario: true });
                    console.log(fileStatus);
                },
                (error) => {
                    console.log(error);
                    reject(error)
                },
                () => {
                    return uploadCVTask.snapshot.ref.getDownloadURL().then(url => {
                        this.setState({ fileModalShow: false });
                        resolve(url)
                    })
                });
    
        })
    }

    sendEmailAppliedToVacante = async () => {
        let email = await fire.firestore().collection('emailTemplates').doc("emailApplied").get()
        var sendEmailAplicar = fire.functions().httpsCallable('sendEmailAplicar');
        await sendEmailAplicar({
            email: this.state.form.email,
            name: this.state.form.name,
            jobTitle: this.state.jobpost.name,
            emailBody: email.data().body
        })
    }
    addApplicationToRecentActivity = async (candidateId, timestamp) => {
        await fire.firestore().collection('recentActivity').add({
            activity: "Candidato nuevo",
            candidateId: candidateId,
            jobpost: this.state.jobpost.name,
            jobpostId: this.state.jobpost.id,
            name: this.state.form.name,
            timestamp: timestamp
        })
    }

    addCandidateCardToTrello = async (candidateDocRef) => {
        var allLinks = ""
        var extraQuestionsText = ""
        this.state.form.links.forEach(link => {
            allLinks = allLinks + link + "\n"
        })
        this.state.jobpost.extraQuestions.forEach(question => {
            let answer = question.answer || ""
            extraQuestionsText = extraQuestionsText + (question.question + "\nA: " + answer) + "\n\n"
        })
        const description = "**Contacto**\n"
            + "Correo electrónico: " + this.state.form.email + "\n"
            + "Telefono: " + this.state.form.phoneNumber + "\n\n"

            + "**Información personal**\n"
            + this.state.form.residence + "\n"
            + "¿Por qué quieres trabajar en SISU?: " + this.state.form.reasonForApplication + "\n"
            + "Sobre el candidato: " + this.state.form.selfDescription + "\n"

            + "**Académico y laboral**\n"
            + "Curriculum Vitae: " + candidateDocRef.cvURL + "\n"
            + "Sueldo deseado: " + this.state.form.desiredSalary + "\n"
            //////////////////
            + "\n**Preguntas adicionales**\n"
            + extraQuestionsText
            //////////////////
            + allLinks
            + "\n\n**Dashboard de candidatos**: "
            + `careers.sisu.mx/candidato-perfil/${candidateDocRef.id}/${this.state.jobpost.id}/${this.state.jobpost.name.split(' ').join('_')}`

        var addCandidateCardToTrello = fire.functions().httpsCallable('addCandidateCardToTrello');
        await addCandidateCardToTrello({
            name: this.state.form.name,
            description: description,
            vacante: this.state.jobpost.name,
            labelColor: this.state.jobpost.labelColor
        })
    }

    handleSubmit = async (event) => {
        event.preventDefault()

        if (!this.state.hasUploadedCV) {
            this.openModal("Aviso", "Es necesario que incluyas un archivo Pdf con tu CV para enviar la aplicación.")
        }
        else if (!this.state.form.acceptPrivacyTerms) {
            this.openModal("Aviso", "Debe aceptar los términos y condiciones antes de completar el proceso de solicitud")
        }
        else if (this.state.form.email.includes(",")) {
            this.openModal("Aviso", "Solo puedes entrar un correo electrónico")
        }
        else {
            this.setState({ ...this.state, enviandoFormulario: true });

            try {
                if (await this.doesEmailExist()) {

                    let cvURL = await this.uploadCVToFirebaseStorageWithProgress()
                    var uploadCandidate = fire.functions().httpsCallable('createAndUploadUser');
                    let result = await uploadCandidate({
                        form: { ...this.state.form },
                        jobpost: { ...this.state.jobpost },
                        cvURL: cvURL,
                    })
                    if (result.data.message === "Finished successfuly...") {

                        this.setState({
                            enviandoFormulario: false,
                            applicationCompleted: true,
                        }, () => { this.openModal('Aplicación enviada con éxito', 'Tu solicitud ha sido enviada exitosamente! :) Gracias por aplicar a la vacante ' + this.state.jobpost.name + ', estaremos en contacto pronto.') });
                    }
                }
            }
            catch (error) {
                this.setState({ enviandoFormulario: false }, () => { document.body.style.overflow = "scroll" })
                this.openModal('Aviso', 'Ha ocurrido un error. Por favor intente de nuevo')
                console.log(error)
            }
        }
    }
    openModal = (modalHeader, modalBody) => {
        this.setState({
            modalHeaderText: modalHeader,
            modalBodyText: modalBody,
            modalIsOpen: true
        })
    }
    closeModal = () => {
        this.setState({
            modalHeaderText: '',
            modalBodyText: '',
            modalIsOpen: false,
            privacyModalIsOpen: false,
        }, () => {
            if (this.state.applicationCompleted) {
                this.setState({ closedAppliedModal: true })
            }
        })
    }

    handleOnDrop = (files, rejectedFiles) => {
        files.forEach(file => {
            if (file.name.split('.').pop() === 'pdf') {
                this.setState({ form: { ...this.state.form, cv: files }, file: files[0], fileName: files[0].name, hasUploadedCV: true })
            }
            else {
                window.alert("Solo se aceptan archivos de tipo pdf")
            }
        })
    }

    addLink = () => {
        var links = this.state.form.links
        links.push("")
        this.setState({ form: { ...this.state.form, links: links } })
    }
    removeLink = (index) => {
        var links = this.state.form.links
        links.splice(index, 1)
        this.setState({ form: { ...this.state.form, links: links } })
    }
    handleFormLinkInputChange = (event, index) => {
        var links = this.state.form.links
        links[index] = event.target.value
        this.setState({ form: { ...this.state.form, links: links } })
    }

    removeFile = () => {
        this.setState({
            fileName: 'ningún archivo elegido',
            files: [],
            file: '',
            hasUploadedCV: false,
        })
    }

    openPrivacyModal = () => {
        this.setState({
            privacyModalIsOpen: true
        })
    }

    render() {

        if (this.state.applicationCompleted && this.state.closedAppliedModal) {
            return <Redirect to={'/'} />
        }
        if (this.state.isLoading)
            return null
        else if (!this.state.activated)
            return <h1>Esta vacante está deshabilitada</h1>
        else {
            return (

                <ClassToggleStylede>
                    <div id="trigger" />
                    <Controller>

                        <Scene duration="1" classToggle={['.top-bar', 'is-anchored']} reverse={true} triggerHook={0} triggerElement={'#trigger'} indicators={false} >
                            {(progress, event) => (<div></div>)}
                        </Scene>
                        <Scene duration="0" offset="1" classToggle={['.top-bar', 'is-stuck']} reverse={true} triggerHook={0} triggerElement={'#trigger'} indicators={false} >
                            {(progress, event) => (<div></div>)}
                        </Scene>
                        <Scene duration="0" classToggle={['.top-bar', 'no-white']} reverse={true} triggerHook={0} triggerElement={'#apply-form'} indicators={false} pin>
                            {(progress, event) => (<div></div>)}
                        </Scene>

                    </Controller>
                    <div>
                        <TopBar toggleResponsiveClass={() => this.setState({ responsiveClass: this.state.responsiveClass === "" ? "d-block" : "" })} responsiveClass={this.state.responsiveClass || ""} />

                        <ApplyForm
                            vacanteName={this.state.jobpost.name}
                            name={this.state.form.name}
                            email={this.state.form.email}
                            phoneNumber={this.state.form.phoneNumber}
                            residence={this.state.form.residence}
                            desiredSalary={this.state.form.desiredSalary}
                            selfDescription={this.state.form.selfDescription}
                            links={this.state.form.links}
                            addLink={this.addLink}
                            removeLink={this.removeLink}
                            removeFile={this.removeFile}
                            handleFormLinkInputChange={this.handleFormLinkInputChange}
                            fileName={this.state.fileName}
                            acceptPrivacyTerms={this.state.form.acceptPrivacyTerms}
                            enviandoFormulario={this.state.enviandoFormulario}
                            handleOnDrop={this.handleOnDrop}
                            handleFormInputChange={this.handleFormInputChange}
                            handleFormCheckboxChange={(event) => this.setState({ form: { ...this.state.form, [event.target.name]: event.target.checked } })}
                            handleSubmit={this.handleSubmit}
                            openPrivacyModal={this.openPrivacyModal}
                            extraQuestions={this.state.jobpost.extraQuestions}
                            handleFormInputQuestionChange={this.handleFormInputQuestionChange} 
                            fileStatus={this.state.fileStatus}/>


                        <Modal modalIsOpen={this.state.modalIsOpen}
                            closeModal={this.closeModal}
                            modalHeaderText={this.state.modalHeaderText}
                            modalBodyText={this.state.modalBodyText} />

                        <PrivacyPolicyModal modalIsOpen={this.state.privacyModalIsOpen}
                            closeModal={this.closeModal}
                            modalHeaderText={this.state.modalHeaderText}
                            modalBodyText={this.state.modalBodyText} />

                        <Footer />
                    </div >
                </ClassToggleStylede>
            )
        }
    }
}


export default JobPostForm;