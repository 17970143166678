import React from 'react';

import Button from '@material-ui/core/Button';

const VacanteForm = (props) => {
    return (
        <section id="page-title">
            <div class="row">

                <div class="columns   ">
                    <h1>{props.editingVacante ? 'EDITAR VACANTE' : 'CREAR VACANTE'}</h1>
                    <h2>{props.editingVacante ? props.nombre : ''}</h2>
                    <h3>{props.editingVacante ? props.activadoText : ''}</h3>
                    <div style={{ width: 96, height: 14, backgroundColor: props.labelColor, marginRight: 12, borderRadius: 3, marginTop: 6 }}></div>

                </div>

                <div class="columns shrink  ">
                    <div class="row align-right align-middle">
                        {props.editingVacante &&
                            <Button class="button" style={{backgroundColor: "#144681", color:"white"}} onClick={props.toggleActivateVacante}>{props.activado ? 'DESACTIVAR VACANTE' : 'ACTIVAR VACANTE'}</Button>
                        }
                    </div>
                </div>

            </div>
        </section>

    )
};

export default VacanteForm;