import React, { Component, } from 'react';

import { Redirect } from 'react-router';
import Select from 'react-select';
import Dropzone from 'react-dropzone'
import { CircularProgress } from 'material-ui/Progress';
import Close from '@material-ui/icons/Close';
import Add from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

import fire from '../firebase';
import TopBar from '../components/TopBar'


var selectOptions = []
var labelColors = []
class NewCandidate extends Component {

    INITIAL_STATE = {
        name: '',
        residence: '',
        selfDescription: '',
        email: '',
        phoneNumber: '',
        cv: '',
        desiredSalary: '',
        links: [""],

        reasonForApplication: '',

        jobpostId: '',
        jobpost: '',
        MagicLinkURL: '',
        labelColor: '',
        selectedIndex: -1,
        enviandoFormulario: false,
        doneUploading: false,

        hasUploadedCV: false,
        cv: '',
        file: '',
        fileName: '',

        selectedOption: -1,
        displayOption: 'Selecciona',
    }

    constructor(props) {
        super(props);

        this.state = { ...this.INITIAL_STATE };


        this.handleSubmit = this.handleSubmit.bind(this);

    }
    componentDidMount() {
        let db = fire.firestore();

        db.collection('jobposts').get().then((vacantes) => {
            var index = 0
            selectOptions = []
            labelColors = []
            vacantes.forEach((vacante) => {
                selectOptions.push({ value: vacante.id, label: vacante.data().nombre, index: index })
                labelColors.push({ labelColor: vacante.data().labelColor })
                index++
            })
            this.setState({})
        })
    }

    handleFormInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSelection = (selectedOption) => {
        this.setState({ selectedOption: selectedOption.value, jobpostId: selectedOption.value, displayOption: selectedOption.label, selectedIndex: selectedOption.index });
    }

    addLink = () => {
        var links = this.state.links
        links.push("")
        this.setState({ links: links })
    }
    removeLink = (index) => {
        var links = this.state.links
        links.splice(index, 1)
        this.setState({ links: links })
    }
    handleFormLinkInputChange = (event, index) => {
        var links = this.state.links
        links[index] = event.target.value
        this.setState({ links: links })
    }

    doesEmailExist = () => {
        let jobpostDocId = this.state.jobpostId;

        return new Promise((resolve, reject) => {
            const db = fire.firestore();

            db.collection('jobposts').doc(jobpostDocId).collection('candidates').where('email', '==', this.state.email).get().then((emails) => {
                if (emails.size === 0) {
                    resolve()
                }
                else {
                    window.alert('El correo electrónico que ingresaste ya existe en nuestra base de datos.')
                    this.setState({ enviandoFormulario: false })
                    //reject({ message: "Este correo electrónico ya existe en nuestra base de datos." })
                }
            }).catch(error => {
                reject(error)
            })

        })
    }

    createAndUploadUserApplicationToFirestore = () => {
        var candidateDocRef = ''
        return this.getCurrentServerTimestamp().then((result) => {

            return this.addCandidateDocToFirestore(result.data.timestamp)

        }).then((candidateDoc) => {
            candidateDocRef = candidateDoc

            return this.uploadCVToFirebaseStorage()

        }).then((fileSnapshot) => {

            return fileSnapshot.ref.getDownloadURL()

        }).then((fileURL) => {
            this.setState({ cvURL: fileURL })
            //add the download url and magicLink to the already created candidate document
            return this.setCandidateCVAndMagicLink(candidateDocRef, fileURL)

        }).then(() => {

            return candidateDocRef
        })
    }
    getCurrentServerTimestamp = () => {
        var getCurrentTimestamp = fire.functions().httpsCallable('getTimeStampCallable');
        return getCurrentTimestamp()
    }

    addCandidateDocToFirestore = (currentTimestamp) => {
        const db = fire.firestore();
        let jobpostDocId = this.state.jobpostId;

        return db.collection('jobposts').doc(jobpostDocId).collection('candidates').add({
            name: this.state.name,
            email: this.state.email,
            phoneNumber: this.state.phoneNumber,
            residence: this.state.residence,
            reasonForApplication: this.state.reasonForApplication,
            links: this.state.links,
            selfDescription: this.state.selfDescription,
            desiredSalary: this.state.desiredSalary,
            appliedTimestamp: currentTimestamp,

            cv: '',
            cvFilename: this.state.name + "_" + this.state.cv[0].name,
            pruebas: []
        })

    }
    uploadCVToFirebaseStorage = () => {
        let cv = this.state.cv[0];

        let cvStoragePath = `cv/${this.state.name}_${cv.name}`
        const cvRef = fire.storage().ref().child(cvStoragePath)

        return cvRef.put(cv)
    }
    setCandidateCVAndMagicLink = (candidateDocRef, fileURL) => {
        let jobpostDocId = this.state.jobpostId;
        this.setState({ MagicLinkURL: `https://careers.sisu.mx/recruitment-status/${jobpostDocId}/${candidateDocRef.id}` })
        return candidateDocRef.set({
            MagicLinkURL: `https://careers.sisu.mx/recruitment-status/${jobpostDocId}/${candidateDocRef.id}`,
            cv: fileURL
        },
            { merge: true }
        )
    }

    addApplicationToRecentActivity = (candidateDocRef) => {
        let db = fire.firestore();

        return this.getCurrentServerTimestamp().then((result) => {

            return db.collection('recentActivity').add({
                activity: "Candidato nuevo",
                candidateId: candidateDocRef.id,
                jobpost: this.state.displayOption,
                jobpostId: this.state.jobpostId,
                name: this.state.name,
                timestamp: result.data.timestamp
            })
        })
    }

    addCandidateCardToTrello = () => {
        const description = "Contacto\n"
            + "Correo electrónico: " + this.state.email + "\n"
            + "Telefono: " + this.state.phoneNumber + "\n\n"
            + "Información personal\n"
            + this.state.residence + "\n"
            + (this.state.canLiveInMexicoCity ? "Está dispuesto a radicar en la ciudad\n\n" : "No está dispuesto a radicar en la ciudad\n\n")
            + "Académico y laboral\n"
            + "Curriculum Vitae: " + this.state.cvURL + "\n"
            + "Sueldo deseado: " + this.state.desiredSalary + "\n"
            + "Linkedln URL: " + this.state.LinkedInURL + "\n"
            + "Portfolio URL: " + this.state.portfolioURL + "\n\n"
            + "Prueba Dashboard URL: " + this.state.MagicLinkURL + "\n\n"

        var addCandidateCardToTrello = fire.functions().httpsCallable('addCandidateCardToTrello');

        return addCandidateCardToTrello({
            name: this.state.name,
            description: description,
            vacante: this.state.displayOption,
            labelColor: labelColors[this.state.selectedIndex].labelColor
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        var candidateDocRef = ''

        if (this.state.selectedOption === -1) {
            window.alert("Por favor selecciona un vacante para este candidato")
        }
        else if (!this.state.hasUploadedCV) {
            window.alert("Necesitas subir un CV")
        }
        else {
            this.setState({ enviandoFormulario: true });

            this.doesEmailExist().then(() => {

                return this.createAndUploadUserApplicationToFirestore()

            }).then((candidateDoc) => {
                candidateDocRef = candidateDoc
                return this.addApplicationToRecentActivity(candidateDocRef)

            }).then(() => {

                return this.addCandidateCardToTrello()

            }).then(() => {
                this.setState({
                    enviandoFormulario: false,
                    doneUploading: true
                });
            }).catch(error => {
                this.setState({ enviandoFormulario: false })
                window.alert(error)
            })
        }

    }

    handleOnDrop = (files, rejectedFiles) => {
        files.forEach(file => {
            if (file.name.split('.').pop() === 'pdf') {
                this.setState({ cv: files, file: files[0], fileName: files[0].name, hasUploadedCV: true })
            }
            else {
                window.alert("Solo se aceptan archivos de tipo pdf")
            }
        })
    }

    render() {
        if (this.state.doneUploading) {
            window.alert("Candidato agregado exitosamente!")
            return <Redirect to={`/Candidatos`} />
        }
        return (
            <div id="admin">
                <TopBar />
                <form onSubmit={this.handleSubmit} encType="multipart/form-data">

                    <section id="page-title">
                        <div class="row">
                            <div class="columns   ">

                                <h1>AGREGAR CANDIDATO</h1>
                            </div>

                        </div>
                    </section>
                    <section id="candidate-edit" class="">
                        <div class="row">
                            <div class="columns medium-12  ">

                                <div class="row">
                                    <div class="columns medium-12  ">

                                        <div class="grid-t">
                                            <div class="section-title">Vacante</div>
                                            <Select
                                                value={this.state.selectedOption}
                                                onChange={this.handleSelection}
                                                options={selectOptions}
                                                placeholder={this.state.displayOption}
                                            >
                                            </Select>
                                            <br />
                                            <div class="section-title">Información personal</div>
                                        </div>
                                        <div class="grid-c">
                                            <div >Nombre completo</div>
                                            <div >
                                                <input name="name" type="text" placeholder="Nombre completo"
                                                    value={this.state.name}
                                                    onChange={this.handleFormInputChange}
                                                    required="true" />
                                            </div>
                                            <div>Correo electrónico</div>
                                            <div>

                                                <input name="email" type="text" placeholder="Correo electrónico"
                                                    value={this.state.email}
                                                    onChange={this.handleFormInputChange}
                                                    required="true" />

                                            </div>
                                            <div>Teléfono</div>
                                            <div>

                                                <input name="phoneNumber" type="text" placeholder="Teléfono"
                                                    value={this.state.phoneNumber}
                                                    onChange={this.handleFormInputChange}
                                                    required="true" />

                                            </div>

                                            <div >Lugar de residencia</div>
                                            <div>

                                                <input name="residence" type="text" placeholder="Lugar de residencia"
                                                    value={this.state.residence}
                                                    onChange={this.handleFormInputChange}
                                                    required="true" />


                                            </div>
                                            <div>¿Por qué quieres trabajar en SISU?</div>
                                            <div>

                                                <textarea name="reasonForApplication" rows="3" placeholder="¿Por qué quieres trabajar en SISU?"
                                                    value={this.state.reasonForApplication}
                                                    onChange={this.handleFormInputChange}
                                                ></textarea>

                                            </div>
                                            <div>Sobre el candidato</div>
                                            <div>

                                                <textarea name="selfDescription" rows="3" placeholder="Sobre el candidato?"
                                                    value={this.state.selfDescription}
                                                    onChange={this.handleFormInputChange}
                                                ></textarea>

                                            </div>
                                            <div>Sueldo deseado</div>
                                            <div>

                                                <input name="desiredSalary" type="text" placeholder="Sueldo deseado"
                                                    value={this.state.desiredSalary}
                                                    onChange={this.handleFormInputChange}
                                                    required="true" />

                                            </div>
                                            <div></div>
                                            <div className="medium-12 cell ">
                                                <label>Links relevantes (Github, Behance, proyectos, página personal, Linkedin)</label>

                                                {this.state.links.map((link, index) => {
                                                    return (
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <input type="text"
                                                                placeholder="URL"
                                                                value={link}
                                                                onChange={(e) => this.handleFormLinkInputChange(e, index)}
                                                            />
                                                            {index === this.state.links.length - 1 && <Button onClick={this.addLink} className="button-input  button  button--text-thick button--border-medium button--text-upper button--size-s " type="" ><Add /></Button>}
                                                            {index !== 0 && <Button onClick={() => this.removeLink(index)} className="button-input button  button--text-thick button--border-medium button--text-upper button--size-s  " type="" ><Close /></Button>}

                                                        </div>
                                                    )

                                                })}
                                            </div>
                                        </div>








                                        <div class=" grid-t">
                                            <div class="section-title">Adjuntar CV en formato PDF</div>
                                        </div>

                                        <div class="grid-t" >
                                            <Dropzone 
                                                onDrop={this.handleOnDrop}
                                                multiple={false}
                                                maxSize={11111111}

                                            >
                                                {({ getRootProps, getInputProps }) => (

                                                    <div  {...getRootProps()} class="expanded button test-btn " >
                                                        <input  {...getInputProps()} accept="application/pdf" />
                                                        Drag & Drop Here: {this.state.fileName}
                                                    </div>
                                                )}
                                            </Dropzone>
                                        </div>


                                    </div>
                                </div>

                                <div class="row align-right">
                                    <div class="columns shrink">
                                        <button  disabled={this.state.enviandoFormulario} type="submit" class="button">Guardar </button>
                                        {this.state.enviandoFormulario && <CircularProgress size={24} />}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </form>

            </div>
        );
    }
}

export default NewCandidate;