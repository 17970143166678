import React, { Component } from 'react';
import firebaseApp from '../../../firebase';

import Footer from '../../components/Footer';
import TopBar from '../../components/TopBar';

import Phase1 from '../../components/DashboardPhases/Phase1';
import Phase2 from '../../components/DashboardPhases/Phase2';
import Phase3 from '../../components/DashboardPhases/Phase3';

import moment from 'moment';
import 'moment/locale/es';

import Modal from 'react-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import { Controller, Scene } from 'react-scrollmagic';

import styled from 'styled-components';

const ClassToggleStylede = styled.div``;


class PruebaDashboard extends Component {

    INITIAL_STATUS = {
        enviandoFormulario: false,
        beginHomeworkModalIsOpen: false,
        submitHomeworkModalIsOpen: false,
        timeModalIsOpen: false,
        uploadFileModalIsOpen: false,

        pruebaData: undefined,

        candidate: {
            docId: '',
            name: '',
            homework: '',
            challengeAcceptedTimestamp: undefined,
            homeworkSubmittedTimestamp: undefined,

            email: '',
            MagicLinkURL: ''
        },
        jobpost: {
            docId: '',
            name: '',
            challenge: '',
            description: ''
        },
        timer: '',
        phase0: false,
        phase1: false,
        phase2: false,
        phase3: false,
        phase4: false,
        phase5: false,

        phase0Time: '',
        phase1Time: '',
        phase2Time: '',
        phase3Time: '',
        phase4Time: '',
        phase5Time: '',

        passedText: '',
        interviewDate: '',
        phaseArray: [],

        reasonFailed: '',
        whatFailed: '',

        mountedTimestamp: 0,

        beginTestDueDate: '',
        submitTestDueDate: '',

        fileName: 'ningún archivo elegido',
        uploadedHW: false,

        horas: "",
        jobpostDocId: '',

        dueDateBeginTest: 0,
        dueDateCompleteTest: 0,
        hoursToCompleteTest: 0,
        candidateProfileURL: "",
        intervalId: '',
        elapsedTime: 0,
    };

    constructor(props) {
        super(props);
        let candidateDocId = this.props.match.params.candidate;
        let pruebaIndex = this.props.match.params.pruebaIndex
        let jobpostDocId = this.props.match.params.jobpost
        this.state = {
            ...this.INITIAL_STATUS,
            pruebaIndex: pruebaIndex,
            jobpostDocId: jobpostDocId,
            candidate: { ...this.INITIAL_STATUS.candidate, docId: candidateDocId },
        };

        // bindings
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getServerTimestampBeginTest = this.getServerTimestampBeginTest.bind(this);
        this.getServerTimestampSubmitTest = this.getServerTimestampSubmitTest.bind(this);


    }

    componentDidMount() {
        let db = firebaseApp.firestore();
        let candidateRef = db.collection('jobposts').doc(this.state.jobpostDocId).collection('candidates').doc(this.state.candidate.docId);

        var getTimestamp = firebaseApp.functions().httpsCallable('getTimeStampCallable');
        getTimestamp().then((result) => {
            this.setState({ mountedTimestamp: result.data.timestamp })

            candidateRef.get().then(candidateSnap => {
                let candidateData = candidateSnap.data();

                var pruebaData = candidateData.pruebas[this.state.pruebaIndex].pruebaData

                if (candidateData.pruebas[this.state.pruebaIndex].openedDashboard === 0)
                    this.setOpenedDashboard(result.data.timestamp, candidateData.pruebas)

                this.setState({
                    ...this.state,
                    allPruebas: candidateData.pruebas,
                    pruebaData: pruebaData,
                    phase: candidateData.pruebas[this.state.pruebaIndex].phase,
                    timestamps: candidateData.pruebas[this.state.pruebaIndex].timestamps,
                    jobpostName: candidateData.jobpostName,
                    dueDateBeginTest: candidateData.pruebas[this.state.pruebaIndex].dueDateBeginTest,
                    dueDateCompleteTest: candidateData.pruebas[this.state.pruebaIndex].dueDateCompleteTest || 0,
                    hoursToCompleteTest: candidateData.pruebas[this.state.pruebaIndex].pruebaData.completionTimeLimit / 1000 / 60 / 60,
                    candidate: {
                        ...this.state.candidate,
                        name: candidateData.name,
                        email: candidateData.email,
                        submitTestTimeMiliseconds: candidateData.submitTestTimeMiliSeconds,
                    },
                    candidateProfileURL: candidateData.candidateProfileURL
                });
            });
            this.setLeftTime()
            let intervalId = setInterval(() => this.setLeftTime(), 1000);
            this.setState({ ...this.state, intervalId });
        }).catch((error) => {
            // Getting the Error details.
            console.log(error.message);
        });

    }
    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }
    setLeftTime() {
        this.setState({ elapsedTime: this.state.elapsedTime + 1000 });

    }
    getServerTimestampBeginTest() {
        var getTimestamp = firebaseApp.functions().httpsCallable('getTimeStampCallable');
        getTimestamp().then((result) => {
            // Read result of the Cloud Function.
            this.handleBeginTest(result.data.timestamp)
        }).catch((error) => {
            // Getting the Error details.
            console.log(error.message);
        });
    }
    getServerTimestampSubmitTest(event) {
        event.preventDefault()

        if (this.state.uploadedHW) {
            this.setState({ enviandoFormulario: true })

            var getTimestamp = firebaseApp.functions().httpsCallable('getTimeStampCallable');
            getTimestamp().then((result) => {
                // Read result of the Cloud Function.
                this.handleSubmit(result.data.timestamp)
            }).catch((error) => {
                // Getting the Error details.
                console.log(error.message);
            });
        }
        else {
            this.openUploadFileModal()
        }

    }
    setOpenedDashboard = (serverTimestamp, allPruebas) => {
        let db = firebaseApp.firestore();
        let candidateRef = db.collection('jobposts').doc(this.state.jobpostDocId).collection('candidates').doc(this.state.candidate.docId);

        allPruebas[this.state.pruebaIndex] = { ...allPruebas[this.state.pruebaIndex], openedDashboard: serverTimestamp }

        candidateRef.set({
            pruebas: allPruebas
        }, {
                merge: true
            }).then(() => {

            }).catch(error => {
                console.log(error)
            })
    }

    handleBeginTest = (serverTimestamp) => {

        if (this.state.dueDateBeginTest - serverTimestamp < 0)
            window.location.reload()
        else {
            let db = firebaseApp.firestore();
            let candidateRef = db.collection('jobposts').doc(this.state.jobpostDocId).collection('candidates').doc(this.state.candidate.docId);

            candidateRef.get().then(snapshot => {

                var allPruebas = this.state.allPruebas
                allPruebas[this.state.pruebaIndex].phase = 2

                allPruebas[this.state.pruebaIndex].timestamps.push(serverTimestamp)

                allPruebas[this.state.pruebaIndex] = {
                    ...allPruebas[this.state.pruebaIndex],
                    dueDateCompleteTest: serverTimestamp + this.state.pruebaData.completionTimeLimit
                }
                candidateRef.set({
                    pruebas: allPruebas
                }, {
                        merge: true
                    }).then(() => {
                        //window.alert(serverTimestamp + this.state.pruebaData.completionTimeLimit - (serverTimestamp + this.state.elapsedTime) > 0 )
                        this.setState({
                            ...this.state,
                            phase: 2,
                            mountedTimestamp: serverTimestamp,
                            dueDateCompleteTest: serverTimestamp + this.state.pruebaData.completionTimeLimit,
                            challengeAcceptedTimestamp: serverTimestamp,

                        },
                            () => {
                                db.collection('jobposts').doc(this.state.jobpostDocId).get().then(jobpost => {
                                    //add to recent activity
                                    db.collection('recentActivity').add({
                                        activity: "Prueba en casa iniciada",
                                        candidateId: this.state.candidate.docId,
                                        jobpost: jobpost.data().nombre,
                                        jobpostId: this.state.jobpostDocId,
                                        name: this.state.candidate.name,
                                        timestamp: serverTimestamp
                                    }).then(docRef => {

                                    }).catch((error) => {
                                        // Getting the Error details.
                                        console.log("my recent activity " + error.message);
                                    });
                                })
                            })
                    })

            });
        }

    }

    handleSubmit = (serverTimestamp) => {

        let storage = firebaseApp.storage().ref();
        let candidateHomework = this.state.candidate.homework;
        let candidateHomeworkStorage = storage.child(`soluciones-candidatos/${serverTimestamp}_${candidateHomework.name}`);

        candidateHomeworkStorage.put(candidateHomework).then(candidateHomeworkSnap => {
            candidateHomeworkStorage.getDownloadURL().then(downloadURL => {
                let db = firebaseApp.firestore();
                let candidateHomeworkLink = downloadURL;
                let candidateRef = db.collection('jobposts').doc(this.state.jobpostDocId).collection('candidates').doc(this.state.candidate.docId);

                var allPruebas = this.state.allPruebas
                allPruebas[this.state.pruebaIndex].phase = 3

                allPruebas[this.state.pruebaIndex].timestamps.push(serverTimestamp)

                allPruebas[this.state.pruebaIndex] = {
                    ...allPruebas[this.state.pruebaIndex],
                    homework: candidateHomeworkLink,
                    uploadedHomeworkFilename: serverTimestamp + "_" + candidateHomework.name
                }

                candidateRef.set({
                    pruebas: allPruebas
                }, {
                        merge: true
                    }).then(docRef => {
                        this.openTimeModal()

                        firebaseApp.firestore().collection('emailTemplates').doc("emailRevisando").get().then((email) => {

                            var sendEmailRevisandoPrueba = firebaseApp.functions().httpsCallable('sendEmailRevisandoPrueba');
                            sendEmailRevisandoPrueba({
                                MagicLinkURL: this.state.candidate.MagicLinkURL,
                                email: this.state.candidate.email,
                                name: this.state.candidate.name,
                                emailBody: email.data().body
                            }).then(result => {


                                var sendMagicEmailCallable = firebaseApp.functions().httpsCallable('sendMagicEmailCallable');
                                sendMagicEmailCallable({
                                    name: this.state.candidate.name,
                                    pruebaSentTimestamp: moment(this.state.timestamps[0], "x").locale('es').format("DD MMM ") + " a las " + moment(this.state.timestamps[0], "x").locale('es').format("hh:mm a"),
                                    challengeAcceptedTimestamp: moment(this.state.timestamps[1], "x").locale('es').format("DD MMM ") + " a las " + moment(this.state.timestamps[1], "x").locale('es').format("hh:mm a"),
                                    homeworkSubmittedTimestamp: moment(serverTimestamp, "x").locale('es').format("DD MMM ") + " a las " + moment(serverTimestamp, "x").locale('es').format("hh:mm a"),
                                    link: candidateHomeworkLink,
                                    candidateProfileURL: this.state.candidateProfileURL

                                }).then(resultAdmin => {
                                    console.log(8888)
                                    this.setState({ phase: 3 })
                                }).catch((error) => {
                                    // Getting the Error details.
                                    console.log("my email error: " + error.message);
                                });

                            })



                        }).catch((error) => {
                            // Getting the Error details.
                            console.log("my email error: " + error.message);
                        });

                        db.collection('jobposts').doc(this.state.jobpostDocId).get().then(jobpost => {
                            //add to recent activity
                            db.collection('recentActivity').add({
                                activity: "Prueba en casa enviada...",
                                candidateId: this.state.candidate.docId,
                                jobpost: jobpost.data().nombre,
                                jobpostId: this.state.jobpostDocId,
                                name: this.state.candidate.name,
                                timestamp: serverTimestamp
                            }).then(docRef => {

                            }).catch((error) => {
                                // Getting the Error details.
                                console.log("my recent activity " + error.message);
                            });
                        })

                        this.openSubmitHomeworkModal();
                        this.setState({
                            ...this.state,
                            candidate: {
                                ...this.state.candidate,
                                homeworkSubmittedTimestamp: serverTimestamp
                            },
                        })
                    });
            })
        });
    }

    getLeftTime(dueDate) {
        let leftTime = dueDate - this.state.mountedTimestamp;

        return leftTime;
    }


    openBeginHomeworkModal = () => {
        this.setState({ beginHomeworkModalIsOpen: true });
        this.getServerTimestampBeginTest();
    }
    closeBeginHomeworkModal() {
        this.setState({ beginHomeworkModalIsOpen: false });
    }

    openSubmitHomeworkModal() {
        this.setState({ submitHomeworkModalIsOpen: true });
    }
    closeSubmitHomeworkModal() {
        this.setState({ submitHomeworkModalIsOpen: false });
    }

    openUploadFileModal() {
        this.setState({ uploadFileModalIsOpen: true });
    }
    closeUploadFileModal() {
        this.setState({ uploadFileModalIsOpen: false });
    }

    openTimeModal() {
        this.setState({ timeModalIsOpen: true });
    }
    closeTimeModal() {
        if (this.state.horas !== "") {
            let db = firebaseApp.firestore();
            let candidateRef = db.collection('jobposts').doc(this.state.jobpostDocId).collection('candidates').doc(this.state.candidate.docId)

            var allPruebas = this.state.allPruebas
            allPruebas[this.state.pruebaIndex].totalHoursToComplete = this.state.horas

            candidateRef.set({
                pruebas: allPruebas
            }, {
                    merge: true
                })
            this.setState({ timeModalIsOpen: false });
        }
        else
            window.alert("Por favor escriba la cantidad de horas que le tomó completar la prueba")
    }

    handleOnDrop = (files, rejectedFiles) => {
        if (rejectedFiles.length > 0 && rejectedFiles[0].size > 50000000)
            window.alert("El tamaño del archivo debe ser menos de 50 mb.")
        else {
            this.setState({
                candidate: { ...this.state.candidate, homework: files[0], fileName: files[0].name },
                fileName: files[0].name, file: files[0], uploadedHW: true
            })
        }
    }

    failCandidate(reasonFailed) {
        let db = firebaseApp.firestore();
        let candidateRef = db.collection('jobposts').doc(this.state.jobpost.docId).collection('candidates').doc(this.state.candidate.docId);
        var reason = this.state.phase2 ? "entregando" : "iniciando"

        candidateRef.set({
            ApplicationPhase: [
                ...this.state.phaseArray,
                {
                    phase: 5,
                    timestamp: this.state.mountedTimestamp,
                    whatFailed: "Rechazado",
                    reasonFailed: reasonFailed,
                    reason: reason
                }
            ],
            rechazado: true
        }, {
                merge: true
            }).then(() => {

                var sendEmailFin = firebaseApp.functions().httpsCallable('sendEmailFin');
                sendEmailFin({
                    email: this.state.candidate.email,
                    name: this.state.candidate.name,
                }).then(result => {

                }).catch((error) => {
                    // Getting the Error details.
                    console.log("my email error: " + error.message);
                });

                //add to recent activity
                db.collection('recentActivity').add({
                    activity: "Candidato rechazado por tiempo, " + reason,
                    candidateId: this.state.candidate.docId,
                    jobpost: this.state.jobpost.name,
                    jobpostId: this.state.jobpostDocId,
                    name: this.state.candidate.name,
                    timestamp: this.state.mountedTimestamp
                }).then(docRef => {
                    window.location.reload()

                }).catch((error) => {
                    // Getting the Error details.
                    console.log("my recent activity " + error.message);
                });

            }).catch(error => {
                console.log(error.message)
            })
    }
    removeFile = () => {
        this.setState({
            candidate: { ...this.state.candidate, homework: '', fileName: 'ningún archivo elegido' },
            fileName: 'ningún archivo elegido', uploadedHW: false
        })
    }

    render() {
        const nameSplit = this.state.candidate.name.split(' ')
        console.log("CANDI",this.state.candidate)
        console.log("job1",this.state.jobpost)
        return (
            <ClassToggleStylede>
                <div id="trigger" />
                <Controller>

                    <Scene duration="1" classToggle={['.top-bar', 'is-anchored']} reverse={true} triggerHook={0} triggerElement={'#trigger'} indicators={false} >
                        {(progress, event) => (<div></div>)}
                    </Scene>
                    <Scene duration="0" offset="1" classToggle={['.top-bar', 'is-stuck']} reverse={true} triggerHook={0} triggerElement={'#trigger'} indicators={false} >
                        {(progress, event) => (<div></div>)}
                    </Scene>
                    <Scene duration="0" classToggle={['.top-bar', 'no-white']} reverse={true} triggerHook={0} triggerElement={'#dashboard'} indicators={false} pin>
                        {(progress, event) => (<div></div>)}
                    </Scene>

                </Controller>
                <div>
                    <TopBar />

                    <section id="" className="  b-color bg-1" >

                        <div className="row  align-middle align-center ">
                            <div className="columns small-12  text-center ">
                                <h1 className="fs-3 fw-5">Dashboard de candidato</h1>
                                <h2 className="fs-6 fw-5">{this.state.candidate.name}</h2>
                            </div>

                        </div>
                    </section>

                    <section id="dashboard" className="  ">

                        <div className="row align-center ">
                            <div className="columns small-12 medium-3  text-center align-center ">
                                <div className="card">
                                    <div className="candidate-letters">{nameSplit[0].split('')[0]}{nameSplit.length > 1 ? nameSplit[nameSplit.length - 1].split('')[0] : ""}</div>
                                    <div className="candidate-name">{this.state.candidate.name}</div>
                                    <div className="job-name">{this.state.jobpostName}</div>

                                    <div className="dashboard-info">
                                        Este es un espacio personal para realizar la prueba para esta vacante. Puedes guardar esta dirección o acceder a ella desde el correo electrónico que te enviamos.
                                </div>
                                </div>
                            </div>
                            <div className="columns small-12 medium-9  ">
                                {this.state.phase === 1 &&
                                    <Phase1
                                        phase1TimeFormatted={moment(this.state.timestamps[0], "x").locale('es').format("DD MMM YYYY hh:mm a")}
                                        jobName={this.state.jobpostName}
                                        dueDate={this.state.dueDateBeginTest}
                                        phase1Time={this.state.timestamps[0]}
                                        challenge={this.state.pruebaData.challenge}
                                        openBeginHomeworkModal={this.openBeginHomeworkModal}
                                        description={this.state.pruebaData.fullDescriptionHTML}
                                        hoursToCompleteTest={this.state.hoursToCompleteTest}
                                        mountedTimestamp={this.state.mountedTimestamp}
                                        elapsedTime={this.state.elapsedTime}
                                    />}
                                {this.state.phase === 2 &&
                                    <Phase2
                                        jobName={this.state.jobpostName}
                                        challenge={this.state.pruebaData.challenge}
                                        description={this.state.pruebaData.fullDescriptionHTML}
                                        mountedTimestamp={this.state.mountedTimestamp}
                                        handleSubmit={this.getServerTimestampSubmitTest}
                                        handleOnDrop={this.handleOnDrop}
                                        enviandoFormulario={this.state.enviandoFormulario}
                                        fileName={this.state.fileName}
                                        phase2TimeFormatted={moment(this.state.timestamps[1], "x").locale('es').format("DD MMM YYYY hh:mm a")}
                                        removeFile={this.removeFile}

                                        phase1TimeFormatted={moment(this.state.timestamps[0], "x").locale('es').format("DD MMM YYYY hh:mm a")}
                                        dueDate={this.state.dueDateCompleteTest}
                                        phase1Time={this.state.timestamps[0]}
                                        hoursToCompleteTest={this.state.hoursToCompleteTest}
                                        elapsedTime={this.state.elapsedTime}
                                    />}
                                {this.state.phase === 3 &&
                                    <Phase3
                                        phase3TimeFormatted={moment(this.state.timestamps[2], "x").locale('es').format("DD MMM YYYY hh:mm a")}
                                    />}

                            </div>
                        </div>
                    </section>




                    <Footer />

                    <Modal
                        isOpen={this.state.beginHomeworkModalIsOpen}
                        onRequestClose={() => this.closeBeginHomeworkModal()}

                        className="small-modal"
                    >
                        <div>
                            <p className="fs-7 modal-title">Prueba iniciada</p>
                            <p className=" modal-content">Has iniciado la prueba en casa, recuerda que tienes un tiempo límite para entregarla.</p>

                            <a id="magicModalBtn" onClick={() => this.closeBeginHomeworkModal()} className="expanded button button--shikoba button--text-thick button--border-medium button--text-upper button--size-l">
                                <FontAwesomeIcon className="button__icon icon" icon={faCheckCircle} />
                                <span>Aceptar</span>
                            </a>
                        </div>

                    </Modal>

                    <Modal
                        isOpen={this.state.submitHomeworkModalIsOpen}
                        onRequestClose={() => this.closeSubmitHomeworkModal()}
                        className="small-modal"
                    >
                        <div>
                            <p className="fs-7 modal-title">Prueba enviada con éxito</p>
                            <p className=" modal-content">Tu prueba ha sido enviada para su revisión, estaremos en contacto.</p>

                            <a id="magicModalBtn" onClick={() => this.closeSubmitHomeworkModal()} className="expanded button button--shikoba button--text-thick button--border-medium button--text-upper button--size-l">
                                <FontAwesomeIcon className="button__icon icon" icon={faCheckCircle} />
                                <span>Aceptar</span>
                            </a>
                        </div>
                    </Modal>


                    <Modal
                        isOpen={this.state.timeModalIsOpen}
                        //onRequestClose={() => this.closeTimeModal()}
                        className="small-modal"
                    >

                        <div>

                            <p className="fs-7 modal-title">Información adicional</p>
                            <p className=" modal-content">¿Podrías compartirnos el tiempo aproximado que te tomó completar la prueba?</p>


                            <div className="medium-12 cell inputcontainer">

                                <input name="horas" type="text" placeholder="Horas de desarrollo" type="number"
                                    onChange={(e) => this.setState({ horas: e.target.value })} />
                                <label>Horas de desarrollo</label>
                            </div>

                            <a id="magicModalBtn" onClick={() => this.closeTimeModal()} className="expanded button button--shikoba button--text-thick button--border-medium button--text-upper button--size-l">

                                <FontAwesomeIcon className="button__icon icon" icon={faCheckCircle} />

                                <span>Aceptar</span>
                            </a>


                        </div>
                    </Modal>


                    <Modal
                        isOpen={this.state.uploadFileModalIsOpen}
                        onRequestClose={() => this.closeUploadFileModal()}
                        className="small-modal"
                    >

                        <div>

                            <p className="fs-7 modal-title">Aviso</p>
                            <p className=" modal-content">Es necesario que adjuntes la solución de tu prueba.</p>

                            <a id="magicModalBtn" onClick={() => this.closeUploadFileModal()} className="expanded button button--shikoba button--text-thick button--border-medium button--text-upper button--size-l">

                                <FontAwesomeIcon className="button__icon icon" icon={faCheckCircle} />

                                <span>Aceptar</span>
                            </a>


                        </div>
                    </Modal>
                </div >
            </ClassToggleStylede>
        );
    }

}


export default PruebaDashboard;