import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { CircularProgress } from 'material-ui/Progress';

const PruebaForm = (props) => {
    return (
        <div>
            <section id="page-title">
                <div class="row">
                    <div class="columns   ">
                        <h1>{props.headerText}</h1>
                    </div>
                </div>
            </section>

            <section id="vacante-edit" class="">
                <form onSubmit={props.handleSubmit}>
                    <div class="row">
                        <div class="columns medium-12  ">

                            <div class="grid-c">
                                <div >Nombre prueba</div>
                                <div >
                                    <input name="nombre" type="text" placeholder="Vacante"
                                        required="true"
                                        value={props.nombre}
                                        onChange={props.handleFormInputChange} />
                                </div>

                                <div style={{marginTop: 32}}>Descripción básica para mostrar en la tabla o correo</div>
                            <div style={{marginTop: 32}}>
                                <textarea name="descripción" rows="2" placeholder="Descripción Básica"
                                    required="true"
                                    onChange={props.handleFormInputChange}
                                    defaultValue={props.descripción}
                                ></textarea>
                            </div>
                            </div>

                            <div style={{ marginTop: 22, }} class="row align-right">
                                <div>Descripción</div>
                                <div style={{ maxWidth: 908, alignItems: 'flex-end' }} class="columns shrink  ">

                                    <Editor
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        placeholder="Escribir descripción..."
                                        editorStyle={{ border: "1px solid rgba(0, 0, 0, .2)", padding: 8, marginBottom: 10, minHeight: 300 }}
                                        onEditorStateChange={props.onEditorStateChange}
                                        defaultEditorState={props.defaultEditorState}
                                    />
                                </div>
                            </div>

                            <div class="grid-c">
                                <div >Tiempo limite para iniciar (horas)</div>
                                <div >
                                    <input style={{ width: 200 }} name="beginTimeLimit" type="number" placeholder="Limite para iniciar"
                                        required="true"
                                        defaultValue={props.beginTimeLimit}
                                        onChange={props.handleFormInputChange} />
                                </div>

                                <div >Tiempo limite para entregar (horas)</div>
                                <div >
                                    <input style={{ width: 200 }} name="completionTimeLimit" type="number" placeholder="Limite para contestar"
                                        required="true"
                                        defaultValue={props.completionTimeLimit}
                                        onChange={props.handleFormInputChange} />
                                </div>
                            </div>

                                <div class="file-upload">
                                    <label for="upload" class="upload-cv button expanded">Cargar archivo de prueba: {props.challengeFileName}</label>
                                    <input id="upload"
                                        class="file-upload__input"
                                        type="file"
                                        name="file-upload"
                                        onChange={props.handleOnChangeChallenge}
                                    />
                                    {props.editingPrueba && <a style={{backgroundColor: "#144681", color:"white"}} class="button" href={props.challenge} target="_blank" >Descargar Prueba </a>}
                                </div>


                        </div>
                    </div>




                    <div class="row align-right">
                        <div class="columns shrink" style={{display: "flex", flexDirection: "row"}}>
                            <a style={{backgroundColor: "#144681", color:"white"}} href={'/pruebas'} class="button">Cancelar </a>
                            <button style={{backgroundColor: "#144681", color:"white"}} class="button" type="submit" disabled={props.isSubmittingForm}>Guardar </button>
                            {props.isSubmittingForm && <CircularProgress size={24} />}
                        </div>
                    </div>

                </form>

            </section>
        </div>
    )
};

export default PruebaForm;