import React from 'react';
import { InputLabel, FormGroup, } from 'material-ui';
import Button from 'material-ui/Button';

import { CircularProgress } from 'material-ui/Progress';
import Dropzone from 'react-dropzone'

import moment from 'moment';
import 'moment/locale/es';

import JobPostTimer from '../JobPostTimer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faFileUpload, faCloudUploadAlt, faTrash, faFileAlt } from '@fortawesome/free-solid-svg-icons'


const Phase2 = (props) => {
    return (

        <div className="dashboard-event">
            <div className="card dashboard-status">
                <div className="row align-center ">


                    <div className="columns  text-center align-center ">
                        <i class="las la-clock"></i>
                        <div className="status-data  fs-6">
                            <JobPostTimer mountedTimestamp={props.mountedTimestamp} dueDate={props.dueDate} enviandoFormulario={props.enviandoFormulario} />
                        </div>
                        <div className="status-label">
                            Tiempo restante para subir resultados
                        </div>
                    </div>


                </div>

            </div>

            <div className="card">
                <div className="test">
                    <div className="dashboard-event__date">{props.phase2TimeFormatted}</div>
                    <div className="dashboard-event__title ">Prueba en desarrollo</div>


                    <div className="dashboard-event__content">
                        <div className="dashboard-event__content">
                            <p className="">Al terminar la prueba sube tu solución completa en un archivo zip.</p>
                        </div>
                        <form onSubmit={(event) => props.handleSubmit(event)} encType="multipart/form-data">

                            {props.dueDate - (props.mountedTimestamp + props.elapsedTime) > 0 && <FormGroup>
                                <div className="file-upload">
                                    <Dropzone
                                        onDrop={props.handleOnDrop}
                                        multiple={false}
                                        maxSize={50000000}
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <div>
                                                <label>Adjuntar archivo ZIP </label>
                                                <div {...getRootProps()} className="dropzone">



                                                    {props.fileName === "ningún archivo elegido" ?
                                                        <FontAwesomeIcon className="button__icon icon" icon={faCloudUploadAlt} />
                                                        :
                                                        <FontAwesomeIcon className="button__icon icon" icon={faFileAlt} />
                                                    }
                                                    <input {...getInputProps()} accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed" />
                                                    <div className="dz-msg">
                                                        <div className="dz-instruction">
                                                            {props.fileName === "ningún archivo elegido" ?
                                                                <span className="upper">Haz clic aquí o arrastra tu archivo</span> :
                                                                <span>{props.fileName}</span>}
                                                        </div>

                                                        {props.fileName === "ningún archivo elegido" ?
                                                            "" :
                                                            <span className="dz-file">Archivo a subir</span>
                                                        }


                                                    </div>

                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>

                                    {props.fileName === "ningún archivo elegido" ? "" :
                                        <a className="expanded button button--shikoba button--text-thick button--border-medium button--text-upper button--size-l" onClick={props.removeFile}>
                                            <FontAwesomeIcon className="button__icon icon" icon={faTrash} />
                                            <span>Eliminar archivo elegido</span>
                                        </a>
                                    }

                                </div>

                            </FormGroup>}

                            <button type="submit" disabled={props.dueDate - (props.mountedTimestamp + props.elapsedTime) < 0 || props.enviandoFormulario} className="bt-gray expanded button button--shikoba button--text-thick button--border-medium button--text-upper button--size-l">
                                <FontAwesomeIcon className="button__icon icon" icon={faFileUpload} />
                                <span>Enviar mi prueba</span>
                            </button>
                            {props.enviandoFormulario && <CircularProgress size={36} />}
                        </form>
                    </div>

                </div>
            </div>

            <div className="card">
                <div className="test">

                    <div className="dashboard-event__date">{props.phase1TimeFormatted}</div>
                    <div className="dashboard-event__title ">Prueba en casa</div>

                    <div className="dashboard-event__content">
                        <div dangerouslySetInnerHTML={{ __html: props.description }} />

                        <p className="subtitle fw-6 bt-gray">Importante</p>
                        <p className="">
                            <span>Una vez iniciada la prueba tienes </span>
                            {props.hoursToCompleteTest.toFixed(1).split('.')[1] === '0' ? props.hoursToCompleteTest.toFixed(1).split('.')[0] : props.hoursToCompleteTest.toFixed(1)}h para subir tus resultados
                            </p>


                        <a className=" expanded button button--shikoba button--text-thick button--border-medium button--text-upper button--size-l" href={props.challenge} target="_blank" >
                            <FontAwesomeIcon className="button__icon icon" icon={faDownload} />

                            <span>
                                Descargar archivos nuevamente
                                 </span>
                        </a>

                    </div>


                </div>
            </div>
        </div>
    );
};

export default Phase2;