import React, { Component } from 'react';

class JobPostTimer extends Component {

    constructor(props) {
        super(props);
        this.state = { days: 0, hours: 0, minutes: 0, seconds: 0, intervalId: '', elapsedTime: 0, rechazado: false };
    }

    componentWillMount() {
        this.setLeftTime(this.props.initialDate);
    }

    componentDidMount() {
        let intervalId = setInterval(() => this.setLeftTime(), 1000);
        this.setState({ ...this.state, intervalId });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    setLeftTime() {
        let dueDate = this.props.dueDate

        let leftTime = dueDate - (this.props.mountedTimestamp + this.state.elapsedTime);

        let seconds = Math.floor((leftTime / 1000) % 60);
        let minutes = Math.floor((leftTime / 1000 / 60) % 60);
        let hours = Math.floor(leftTime / (1000 * 60 * 60) % 24);
        let days = Math.floor(leftTime / (1000 * 60 * 60 * 24));
        this.setState({ days, hours, minutes, seconds, elapsedTime: this.state.elapsedTime + 1000 });
        if (leftTime < 0 && !this.props.enviandoFormulario) {
            this.setState({ rechazado: true });
        }
    }

    formatLeftTimeText(number, noun) {
        return number !== 1 ? `${noun}s` : noun;
    }

    formatLeftTime(number) {
        return number >= 0 ? number : 0;
    }

    render() {
        return (
            <div>
                {(this.state.rechazado && !this.props.enviandoFormulario) && <span>Rechazado por tiempo</span>}
                {!this.state.rechazado && <span>{(() => { return `${this.formatLeftTime(this.state.days)} ${this.formatLeftTimeText(this.state.days, 'día')}, ` })()}</span>}
                {!this.state.rechazado && <span>{(() => { return `${this.formatLeftTime(this.state.hours)}h, ` })()}</span>}
                {!this.state.rechazado && <span>{(() => { return `${this.formatLeftTime(this.state.minutes)}m, ` })()}</span>}
                {!this.state.rechazado && <span>{(() => { return `${this.formatLeftTime(this.state.seconds)}s.` })()}</span>}
            </div>
        );
    }

}


export default JobPostTimer;
