import React, { Component } from 'react';

import Footer from '../../components/Footer';
import TopBar from '../../components/TopBar';
import { CareersJobs, CareersOffer, CareersIntro, CareersBenefits, CareersBenefits2 } from "./components";

import fire from '../../../firebase';

import { Controller, Scene } from 'react-scrollmagic';
import styled from 'styled-components';

const ClassToggleStyled = styled.div``;

class FirstPage extends Component {

    INITIAL_STATE = {
        vacantes: [],
        responsiveClass: ""
    };

    constructor(props) {
        super(props);
        this.state = { ...this.INITIAL_STATE };
    }

    componentDidMount() {
        fire.firestore().collection('jobposts').onSnapshot((vacantesSnapshot) => {
            let vacantes = [];
            vacantesSnapshot.forEach((doc) => {
                //Only display the vacante if it is activated
                if (doc.data().activado) {
                    vacantes.push({ ...doc.data(), id: doc.id });
                }
            });
            this.setState({ ...this.state, vacantes });
        });
    }

    render() {
        return (
            <ClassToggleStyled>

                <div id="trigger" />
                <Controller>
                    <Scene duration="1" classToggle={['.top-bar', 'is-anchored']} reverse={true} triggerHook={0} triggerElement={'#trigger'} indicators={false} >
                        {(progress, event) => (<div></div>)}
                    </Scene>
                    <Scene duration="0" offset="1" classToggle={['.top-bar', 'is-stuck']} reverse={true} triggerHook={0} triggerElement={'#trigger'} indicators={false} >
                        <div></div>
                    </Scene>
                    <Scene duration="0" classToggle={['.top-bar', 'no-white']} reverse={true} triggerHook={0} triggerElement={'#careers-offer'} indicators={false} pin>
                        <div></div>
                    </Scene>
                    <Scene duration="0" classToggle={['.top-bar', 'white-again']} reverse={true} triggerHook={0} triggerElement={'#careers-how'} indicators={false} pin>
                        <div></div>
                    </Scene>
                    <Scene duration="0" classToggle={['.top-bar', 'no-white-again']} reverse={true} triggerHook={0} triggerElement={'#careers-jobs'} indicators={false} pin>
                        <div></div>
                    </Scene>
                </Controller>

                <div>
                    <TopBar toggleResponsiveClass={() => this.setState({ responsiveClass: this.state.responsiveClass === "" ? "d-block" : "" })} responsiveClass={this.state.responsiveClass || ""} />

                    <CareersIntro />

                    <CareersOffer />

                    <CareersBenefits />

                    <CareersBenefits2 />

                    <CareersJobs vacantes={this.state.vacantes} />

                    <Footer />
                </div>

            </ClassToggleStyled>
        )
    }

}

export default FirstPage;