import React, { Component } from 'react';
import { Redirect } from 'react-router';

import Button from '@material-ui/core/Button';

import fire from '../firebase';

import TopBar from '../components/TopBar'

class AdminLogin extends Component {

    INITIAL_STATE = { email: '', password: '', loggedIn: false, errorMessage: null }

    constructor(props) {
        super(props);
        this.state = { ...this.INITIAL_STATE };

        // form bindings
        this.handleEmail = this.handleEmail.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.handleLogin = this.handleLogin.bind(this);

    }

    handleEmail(event) {
        let email = event.target.value;
        this.setState({ ...this.state, email: email });
    }

    handlePassword(event) {
        let password = event.target.value;
        this.setState({ ...this.state, password: password });
    }

    handleLogin(event) {
        event.preventDefault()
        const { email, password } = this.state

        fire.auth()
            .signInWithEmailAndPassword(email, password)
            .then(() => {
                this.setState({ loggedIn: true })
            })
            .catch(error => {
                alert(error.message)
                console.log(error.message)
            })
    }

    render() {
        return (
            <div id="admin">

                <TopBar loginScreen={true}/>

                <body id="admin">

                    <section id="login-admin" class="  ">
                        <div class="row align-center align-middle ">
                            <div class="columns medium-6  ">


                                <form onSubmit={this.handleLogin}>


                                    <h1 class="login-title">Ingreso a Admin</h1>
                                    <div class="row align-center ">
                                        <div class="medium-12 columns inputcontainer">
                                            <input type="text" placeholder="Correo Electrónico" required="true"
                                                value={this.state.email}
                                                onChange={(event) => { this.setState({ email: event.target.value }) }} />
                                            <label>Correo Electrónico</label>
                                        </div>
                                        <div class="medium-12 columns inputcontainer">
                                            <input type="password" placeholder="Contraseña" required="true"
                                                value={this.state.password}
                                                onChange={(event) => { this.setState({ password: event.target.value }) }} />
                                            <label>Contraseña</label>
                                        </div>


                                    </div>

                                    <Button class="expanded button fs-7" style={{ backgroundColor: "#144681", color:"white" }} color="primary" type="submit" >Login</Button>
                                    {(() => {
                                        if (this.state.loggedIn) {
                                            return (
                                                <Redirect to={'/feed'} />
                                            );
                                        }
                                    })()}


                                </form>

                            </div>
                        </div>
                    </section>



                    <script src="../assets/js/app.js"></script>

                </body>

            </div>

        );
    }
}

export default AdminLogin;