import React from 'react';

const CareersBenefits2 = (props) => {
    return (

        <section id="careers-how" className="backChange b-img " >
                    <div className="bg-wrap"><div className="fixed-img"  ></div></div>
                    <div className="row align-center ">
                    <div className="columns small-12 medium-10">
                        <div className="row align-center ">
                        <div className="columns small-12 large-5">
                            <h2 className="fs-4 fw-6 ">Cómo trabajamos <span className="nowrap">en SISU</span></h2>
                        </div>

                        <div className="columns small-12 large-7">

                            <div className="row">
                                <div className="service columns small-12 align-self-bottom mb-15 ">
                                    
                                    
                                            <p className="fs-6 fw-5 mb-05">En el día a día</p>

                                        <ul>
                                            <li className="fs-8 fw-4 lh-1  mb-05">Proyectos interesantes y problemas retadores</li>
                                            <li className="fs-8 fw-4 lh-1  mb-05">Libertad de elegir tus propias herramientas de desarrollo</li>
                                            <li className="fs-8 fw-4 lh-1  mb-05">Actividades de aprendizaje continuo</li>
                                        </ul>  
                                </div>
                                <div className="service columns small-12  align-self-bottom mb-15">
                                    
                                    
                                            <p className="fs-6 fw-5 mb-05">En los proyectos</p>
                                     <ul>
                                            <li className="fs-8 fw-4 lh-1  mb-05">Trabajo con compañeros inteligentes y talentosos</li>

                                            <li className="fs-8 fw-4 lh-1  mb-05">Involucramiento en todo el proceso de desarrollo de productos y servicios digitales</li>
                                            <li className="fs-8 fw-4 lh-1  mb-05">Interacción directa con clientes</li>
                                    </ul>       
                                        
                                </div>
                                <div className="service columns small-12  align-self-bottom mb-15">
                                    
                                   
                                             
                                            <p className="fs-6 fw-5 mb-05">En la oficina</p>

                                        <ul>
                                            <li className="fs-8 fw-4 lh-1  mb-05">Autonomía e independencia</li>
                                            <li className="fs-8 fw-4 lh-1  mb-05">Poder de tomar decisiones, tu voz será escuchada</li>
                                            <li className="fs-8 fw-4 lh-1  mb-05">Liderazgo transparente</li>
                                        </ul>
                             
                                </div>
                            </div>



                        </div>

                       </div> 
                       </div> 
                       </div> 
                </section>
    );
};

export default CareersBenefits2;