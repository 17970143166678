import React, { Component, } from 'react';
import { Redirect } from 'react-router';

import { VacanteForm, VacanteTitle } from "./components";
import fire from '../../firebase';
import TopBar from '../../components/TopBar'

import { convertToRaw, ContentState, convertFromHTML, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


class NewVacante extends Component {

    INITIAL_STATE = {
        toVacantes: false,
        nombre: '',
        labelColor: '',
        fullDescriptionHTML: '',
        simpleDescription: '',

        //extraQuestions: [],
        extraQuestions: [{ question: "", optionRequired: true, optionTextInput: true, }],

        editorState: undefined,
        isLoading: false,
    }

    constructor(props) {
        super(props);
        this.state = { ...this.INITIAL_STATE, editorState: undefined };
    }

    componentDidMount() {

    }
    onEditorStateChange = (editorState) => {
        this.setState({
            editorState
        });
    };

    handleFormInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    addQuestion = () => {
        var extraQuestions = this.state.extraQuestions
        extraQuestions.push({ question: "", optionRequired: true, optionTextInput: true, })
        this.setState({ extraQuestions: extraQuestions })
    }
    removeQuestion = (index) => {
        var extraQuestions = this.state.extraQuestions
        extraQuestions.splice(index, 1)
        this.setState({ extraQuestions: extraQuestions })
    }
    handleFormQuestionInputChange = (event, index) => {
        var extraQuestions = this.state.extraQuestions
        extraQuestions[index].question = event.target.value
        this.setState({ extraQuestions: extraQuestions })
    }
    handleOptionRequired = (selectedOption, index) => {
        var extraQuestions = this.state.extraQuestions
        extraQuestions[index].optionRequired = selectedOption.value
        this.setState({ extraQuestions: extraQuestions })
    }
    handleOptionTextInput = (selectedOption, index) => {
        var extraQuestions = this.state.extraQuestions
        extraQuestions[index].optionTextInput = selectedOption.value
        this.setState({ extraQuestions: extraQuestions })
    }

    uploadVacanteToFirestore = () => {
        let db = fire.firestore();
        var getTimestamp = fire.functions().httpsCallable('getTimeStampCallable');

        return getTimestamp().then((result) => {

            return db.collection('jobposts').add({
                nombre: this.state.nombre,
                labelColor: this.state.labelColor,
                fullDescriptionHTML: "" + draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
                simpleDescription: this.state.simpleDescription,
                activado: true,
                createdTimestamp: result.data.timestamp,
                showCandidates: true,
                extraQuestions: this.state.extraQuestions
            })
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ isLoading: true })

        if (this.state.editorState) {
            console.log("" + draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())));
            if (this.state.labelColor === '') {
                this.setState({ isLoading: false })
                window.alert("Por favor seleccione un color de etiqueta para este vacante")
            }
            else if (!this.state.editorState) {
                this.setState({ isLoading: false })
                window.alert("Por favor Por favor escriba una descripción.")
            }
            else {
                this.uploadVacanteToFirestore().then(() => {

                    this.setState({ isLoading: false })
                    this.props.history.push('/vacantes')

                }).catch((error) => {
                    this.setState({ isLoading: false })
                    console.log(error.message);
                });
            }
        }
        else {
            window.alert("La vacante necesita una descripción")
            this.setState({ isLoading: false })
        }

    }

    render() {
        if (this.state.toVacantes) {
            return <Redirect to={'admindashboard'} />
        }
        return (
            <div id="admin">
                <TopBar />


                <VacanteForm handleSubmit={this.handleSubmit}
                    handleFormInputChange={this.handleFormInputChange}
                    nombre={this.state.nombre}
                    labelColor={this.state.labelColor}
                    onEditorStateChange={this.onEditorStateChange}
                    defaultEditorState={undefined}
                    isLoading={this.state.isLoading}
                    
                    addQuestion={this.addQuestion}
                    removeQuestion={this.removeQuestion}
                    handleFormQuestionInputChange={this.handleFormQuestionInputChange}
                    extraQuestions={this.state.extraQuestions}
                    handleOptionRequired={this.handleOptionRequired}
                    handleOptionTextInput={this.handleOptionTextInput} />



            </div>
        );
    }
}
/*
EditorState.createWithContent(
    ContentState.createFromBlockArray(
      convertFromHTML("<p><strong>Hello</strong> <em>trying</em> <span style=\"font-size: 36px;\">here</span> <ins>today</ins></p> <ol> <li>agin</li> </ol> <ul> <li>&nbsp;right now</li> </ul>")
    )
)*/

export default NewVacante;