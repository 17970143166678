import React, { Component, } from 'react';

import Close from '@material-ui/icons/Close';
import Add from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

import fire from '../firebase';
import TopBar from '../components/TopBar'

class EditCandidate extends Component {

    INITIAL_STATE = {
        name: '',
        residence: '',
        selfDescription: '',
        email: '',
        phoneNumber: '',
        cv: '',
        desiredSalary: '',
        links: [],

        reasonForApplication: '',
    }

    constructor(props) {
        super(props);
        let candidateDocId = this.props.match.params.id;
        let jobpostId = this.props.match.params.jobpostid;
        let jobpost = this.props.match.params.jobpost;

        this.state = { ...this.INITIAL_STATE, candidateDocId: candidateDocId, jobpostId: jobpostId, jobpost: jobpost };


        this.handleSubmit = this.handleSubmit.bind(this);

    }
    componentDidMount() {
        fire.firestore().collection('jobposts').doc(this.state.jobpostId).collection('candidates').doc(this.state.candidateDocId).get().then((candidate) => {
            this.setState({
                name: candidate.data().name,
                residence: candidate.data().residence,
                selfDescription: candidate.data().selfDescription,
                email: candidate.data().email,
                phoneNumber: candidate.data().phoneNumber,
                cv: candidate.data().cv,
                desiredSalary: candidate.data().desiredSalary,
                reasonForApplication: candidate.data().reasonForApplication,
                links: candidate.data().links
            })
        })

    }

    handleFormInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit(event) {

        event.preventDefault();

        let db = fire.firestore();

        db.collection('jobposts').doc(this.state.jobpostId).collection('candidates').doc(this.state.candidateDocId).set(
            {
                name: this.state.name,
                residence: this.state.residence,
                reasonForApplication: this.state.reasonForApplication,
                selfDescription: this.state.selfDescription,
                email: this.state.email,
                phoneNumber: this.state.phoneNumber,
                cv: this.state.cv,
                links: this.state.links,
                desiredSalary: this.state.desiredSalary,

            },
            {
                merge: true
            }).then(docRef => {

                this.props.history.push({ pathname: `/candidato-perfil/${this.state.candidateDocId}/${this.state.jobpostId}/${this.state.jobpost}` })
            })
            .catch(function (error) {
                alert(error.code);
            });
    }

    addLink = () => {
        var links = this.state.links
        links.push("")
        this.setState({ links: links })
    }
    removeLink = (index) => {
        var links = this.state.links
        links.splice(index, 1)
        this.setState({ links: links })
    }
    handleFormLinkInputChange = (event, index) => {
        var links = this.state.links
        links[index] = event.target.value
        this.setState({ links: links })
    }

    render() {

        return (
            <div id="admin">
                <TopBar />
                <section id="page-title">
                    <div class="row">
                        <div class="columns   ">

                            <h1>EDITAR CANDIDATO</h1>
                        </div>
                        <div class="columns shrink  ">
                            <div class="row align-right align-middle">
                                <div class="columns ">

                                </div>

                            </div>

                        </div>
                    </div>
                </section>

                <section id="candidate-edit" class="">
                    <div class="row">
                        <div class="columns medium-12  ">

                            <div class="row">
                                <div class="columns medium-12  ">

                                    <div class="grid-t">
                                        <div class="section-title">Información personal</div>
                                    </div>
                                    <div class="grid-c">
                                        <div >Nombre completo</div>
                                        <div >
                                            <input name="name" type="text" placeholder="Nombre completo"
                                                value={this.state.name}
                                                onChange={this.handleFormInputChange} />
                                        </div>
                                        <div>Correo electrónico</div>
                                        <div>

                                            <input name="email" type="text" placeholder="Correo electrónico"
                                                value={this.state.email}
                                                onChange={this.handleFormInputChange} />

                                        </div>
                                        <div>Teléfono</div>
                                        <div>

                                            <input name="phoneNumber" type="text" placeholder="Teléfono"
                                                value={this.state.phoneNumber}
                                                onChange={this.handleFormInputChange} />

                                        </div>

                                        <div >Lugar de residencia</div>
                                        <div>

                                            <input name="residence" type="text" placeholder="Lugar de residencia"
                                                value={this.state.residence}
                                                onChange={this.handleFormInputChange} />


                                        </div>
                                        <div>¿Por qué quieres trabajar en SISU?</div>
                                        <div>

                                            <textarea name="reasonForApplication" rows="3" placeholder="¿Por qué quieres trabajar en SISU?"
                                                value={this.state.reasonForApplication}
                                                onChange={this.handleFormInputChange}
                                            ></textarea>

                                        </div>
                                        <div>Sobre el candidato</div>
                                        <div>

                                            <textarea name="selfDescription" rows="3" placeholder="Sobre el candidato?"
                                                value={this.state.selfDescription}
                                                onChange={this.handleFormInputChange}
                                            ></textarea>

                                        </div>
                                        <div>Sueldo deseado</div>
                                        <div>

                                            <input name="desiredSalary" type="text" placeholder="Sueldo deseado"
                                                value={this.state.desiredSalary}
                                                onChange={this.handleFormInputChange} />

                                        </div>
                                        <div></div>
                                            <div className="medium-12 cell ">
                                                <label>Links relevantes (Github, Behance, proyectos, página personal, Linkedin)</label>

                                                {this.state.links.map((link, index) => {
                                                    return (
                                                        <div >
                                                            <input type="text"
                                                                placeholder="URL"
                                                                value={link}
                                                                onChange={(e) => this.handleFormLinkInputChange(e, index)}
                                                            />
                                                            {index === this.state.links.length - 1 && <Button onClick={this.addLink} className="button-input  button  button--text-thick button--border-medium button--text-upper button--size-s " type="" ><Add /></Button>}
                                                            {index !== 0 && <Button onClick={() => this.removeLink(index)} className="button-input button  button--text-thick button--border-medium button--text-upper button--size-s  " type="" ><Close /></Button>}

                                                        </div>
                                                    )

                                                })}
                                            </div>
                                    </div>

                                </div>
                            </div>

                            <div class="row align-right">
                                <div class="columns shrink">
                                    <button  onClick={this.handleSubmit} class="button">Guardar </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        );
    }
}

export default EditCandidate;