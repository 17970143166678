import React, { Component } from 'react';

import fire from '../../firebase';

import TopBar from '../../components/TopBar'
import { PruebaForm } from "./components";
import { convertToRaw, ContentState, convertFromHTML, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class EditPrueba extends Component {

    INITIAL_STATE = {
        pruebaId: this.props.location.pruebaId,
        nombre: this.props.location.nombre,
        descripción: this.props.location.descripción,
        challengeFileName: this.props.location.challengeFileName,
        challengeURL: this.props.location.challengeURL,

        beginTimeLimit: this.props.location.beginTimeLimit / 3600000,
        completionTimeLimit: this.props.location.completionTimeLimit / 3600000,

        originalChallengeFileName: this.props.location.challengeFileName,
        changedChallenge: false,
        fullDescriptionHTML: this.props.location.fullDescriptionHTML,
        editorState: EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(this.props.location.fullDescriptionHTML || ""))) || undefined,
    }

    constructor(props) {
        super(props);
        this.state = { ...this.INITIAL_STATE };
    }

    handleFormInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }
    handleOnChangeChallenge = (event) => {
        this.setState({
            changedChallenge: true,
            challenge: event.target.files[0],
            challengeFileName: event.target.files[0].name,
        })
    }
    onEditorStateChange = (editorState) => {
        this.setState({
            editorState
        });
    };

    setPruebaDocumentAndReturn = (challengeURL) => {
        let db = fire.firestore()

        db.collection('pruebas').doc(this.state.pruebaId).set(
            {
                nombre: this.state.nombre,
                descripción: this.state.descripción,
                fullDescriptionHTML: "" + draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())), beginTimeLimit: this.state.beginTimeLimit * 3600000, //convert to miliseconds
                challengeFileName: this.state.challengeFileName,
                challenge: challengeURL,
                beginTimeLimit: this.state.beginTimeLimit * 3600000, //convert to miliseconds
                completionTimeLimit: this.state.completionTimeLimit * 3600000, //convert to miliseconds
            },
            {
                merge: true
            }).then(docRef => {
                this.setState({ isSubmittingForm: false })
                this.props.history.push({ pathname: '/pruebas' })
            }).catch(error => {
                this.setState({ isSubmittingForm: false })
                console.log(error.message)
                window.alert(error.message);
            });
    }
    replaceChallengeInStorage = () => {

        var deleteRef = fire.storage().ref().child(`ejercicios-candidatos/${this.state.nombre}_${this.state.originalChallengeFileName}`);
        let challengeStorage = fire.storage().ref().child(`ejercicios-candidatos/${this.state.nombre}_${this.state.challenge.name}`);

        // Delete the old challenge file
        return deleteRef.delete().then(() => {

            return challengeStorage.put(this.state.challenge)
        }).then(() => {

            return challengeStorage.getDownloadURL()
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        let db = fire.firestore();
        this.setState({ isSubmittingForm: true })

        if (this.state.editorState) {
            if (this.state.beginTimeLimit <= 0 || this.state.completionTimeLimit <= 0) {
                window.alert("Las horas deben ser mayores que 0")
                this.setState({ isLoading: false })
            }
            //If the challenge was not changed, 
            //we do not have to upload to firebase storage and get the new download url
            else if (!this.state.changedChallenge) {
                this.setPruebaDocumentAndReturn(this.state.challengeURL)
            }
            else {

                this.replaceChallengeInStorage().then((downloadURL) => {

                    this.setPruebaDocumentAndReturn(downloadURL)
                }).catch(error => {
                    console.log(error.message)
                });
            }
        }
        else {
            window.alert("La vacante necesita una descripción")
            this.setState({ isLoading: false })
        }
    }

    render() {
        return (
            <div id="admin">
                <TopBar />

                <PruebaForm headerText={'Editar PRUEBA'}
                    handleSubmit={this.handleSubmit}
                    handleFormInputChange={this.handleFormInputChange}
                    handleOnChangeChallenge={this.handleOnChangeChallenge}
                    nombre={this.state.nombre}
                    descripción={this.state.descripción}
                    onEditorStateChange={this.onEditorStateChange}
                    defaultEditorState={this.state.editorState}
                    challengeFileName={this.state.challengeFileName}
                    isSubmittingForm={this.state.isSubmittingForm}
                    challenge={this.state.challengeURL}
                    beginTimeLimit={this.state.beginTimeLimit || 0}
                    completionTimeLimit={this.state.completionTimeLimit || 0}
                    editingPrueba={true} />

            </div>
        );
    }
}

const styles = {
    card: {
        minHeight: 2222,
        background: 'blue',
    },

};

export default EditPrueba;