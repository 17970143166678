import React from 'react';
import { default as ReactModal } from 'react-modal';
import { customModalStyles } from "../../../../styles/styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'


const PrivacyPolicyModal = (props) => {
    return (
        <ReactModal
            isOpen={props.modalIsOpen}
            onRequestClose={props.closeModal}

        >

            <div>

                <p className="fs-7 modal-title">Aviso de Privacidad Simplificado del Registro de candidatos</p>
                <p className=" modal-content">
                    

                    <p>SISU TECHNOLOGIES SAPI DE CV, es el responsable del tratamiento de los datos personales que nos proporcione.</p>
                    <p>Sus datos personales serán utilizados para dar seguimiento al proceso de reclutamiento.</p>
                    <p>Sus datos no se compartirán o transferirán sin su consentimiento, salvo en aquellas situaciones donde sean necesarios para atender requerimientos de información de una autoridad competente, debidamente fundados y motivados.</p>

                    <p>Podrá consultar el aviso de privacidad integral <a href="https://sisu.mx/docs/DOC_INT_AvisoPrivacidad_v3.pdf" target="_blank"   >aquí</a>.</p>
                    </p>

                <a id="magicModalBtn" onClick={props.closeModal} className="expanded button button--shikoba button--text-thick button--border-medium button--text-upper button--size-l">

                    <FontAwesomeIcon className="button__icon icon" icon={faCheckCircle} />

                    <span>Aceptar</span>
                </a>


            </div>
        </ReactModal>
    );
};

export default PrivacyPolicyModal;