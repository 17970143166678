import React, { Component, } from 'react';

import Button from '@material-ui/core/Button';

import fire from '../../firebase';
import TopBar from '../../components/TopBar'

import moment from 'moment';
import 'moment/locale/es';

import ReactTable from 'react-table'
import "react-table/react-table.css";

import DeleteIcon from '@material-ui/icons/Delete'
import DownloadExcel from './DownloadExcel'


var data = []
var mm = 99
var unsubscribe = ''

class Vacantes extends Component {

    INITIAL_STATE = { nombre: '', labelColor: '', fullDescrptionHTML: '', simpleDescription: '', }

    constructor(props) {
        super(props);
        this.state = { ...this.INITIAL_STATE };

    }
    componentWillUnmount() {
        //unsubscribe from query snapshot listener
        unsubscribe();
    }
    componentDidMount() {

        let db = fire.firestore();
        unsubscribe = db.collection('jobposts').onSnapshot((querySnapshot) => {
            data = []

            querySnapshot.docs.forEach((doc, index) => {
                
                var activos = 0;
                var rechazados = 0;

                db.collection('jobposts').doc(doc.id).collection('candidates').orderBy('appliedTimestamp', 'desc').get().then((allCandidates) => {
                    let userData = []
                    allCandidates.forEach((candidate) => {
                        activos++
                        userData.push({...candidate.data()})
                    });

                    data.push({
                        Nombre: doc.data().nombre,
                        labelColor: <div style={{ width: 96, height: 14, backgroundColor: doc.data().labelColor, marginRight: 12, borderRadius: 3, marginTop: 6 }}></div>,
                        Fecha_Creación: moment(doc.data().createdTimestamp, "x").locale('es').format("DD MMM YYYY"),
                        Candidatos_Activos: {activos: activos, showCandidates: doc.data().showCandidates, vacanteID: doc.id, index: index},
                        activado: doc.data().activado.toString(),
                        Editar: <Button style={{ color: 'blue' }} onClick={() => this.props.history.push({
                            pathname: `/editar-vacante/${doc.id}`,
                            openingFromButton: true,
                            activado: doc.data().activado,
                            jobpostId: doc.id,
                            nombre: doc.data().nombre,
                            labelColor: doc.data().labelColor,
                            fullDescrptionHTML: doc.data().fullDescrptionHTML,
                            simpleDescription: doc.data().simpleDescription,

                        })}>editar</Button>,
                        Borrar: <Button onClick={() => {
                            if (window.confirm("¿Estás seguro de que deseas eliminar este vacante?")) {
                                this.deleteVacante(doc.id)
                            }
                        }}><DeleteIcon /></Button>,
                        Bajar: <DownloadExcel id="download-excel" userData={userData} title={doc.data().nombre} />
                        ,
                    });
                    this.setState({ labelColor: doc.data().labelColor })
                });

            });
            //this.setState({ data: data })
        });
    }


    deleteCandidatesFromVacante = (jobpostId) => {
        //With Firestore, there is no function for deleting entire collections. 
        //If you delete a document with a populated collection (like the candidates), you must delete all the documents in the collection before then deleting the document itself
        var promises = []

        fire.firestore().collection('jobposts').doc(jobpostId).collection('candidates').get().then((candidates) => {

            candidates.forEach(candidate => {
                promises.push(candidate.ref.delete())
                this.deleteUserAndFiles(candidate.data().cvFilename, candidate.data().pruebas, jobpostId, candidate.ref)
            })
        })

        return Promise.all(promises)
    }

    deleteVacante(jobpostId) {
        let db = fire.firestore();

        this.deleteCandidatesFromVacante(jobpostId).then(() => {

            //now delete the vacante itself after having deleted each candidate docs inside it
            return db.collection("jobposts").doc(jobpostId).delete()
        }).then(() => {

            console.log("Document successfully deleted!");
            window.alert("Vacante eliminado con éxito!")
        }).catch((error) => {

            console.error("Error removing document: ", error);
        });
    }

    deleteUserAndFiles = (cvFilename, allPruebas, docId, candidateRef) => {
        var deleteRef = fire.storage().ref().child(`cv/${cvFilename}`);
        deleteRef.delete().then(() => {

            if (allPruebas !== [])
                this.deleteAllPruebas(allPruebas)


            //window.alert("Borrado con exito...")
        }).catch(error => {
            //window.alert(error.message)
            console.log(error.message)
        });

    }
    deleteAllPruebas = (allPruebas) => {
        let deletePruebaSolucionRef = ""
        allPruebas.forEach(prueba => {
            if (prueba.phase === 3) {
                deletePruebaSolucionRef = fire.storage().ref().child(`soluciones-candidatos/${prueba.uploadedHomeworkFilename}`);
                deletePruebaSolucionRef.delete().then(() => {
                    //window.alert("Borrado con exito...")
                }).catch(error => {
                    //window.alert(error.message)
                    console.log(error.message)
                });
            }
        })


    }

    toggleShowCandidates = (vacanteID, index) => {

        data[index] = {...data[index], Candidatos_Activos: {...data[index].Candidatos_Activos, showCandidates: !data[index].Candidatos_Activos.showCandidates}}
        
        fire.firestore().collection('jobposts').doc(vacanteID).set(
            {
                showCandidates: data[index].Candidatos_Activos.showCandidates
            },
            {
                merge: true
            }).then(docRef => {
                this.setState({})
            })
            .catch(error => {

            });
    
    }

    render() {

        const columns = [{
            Header: 'Nombre.....',
            accessor: 'Nombre'
        }, {
            Header: 'Color de Etiqueta',
            accessor: 'labelColor'
        }, {
            Header: 'Fecha de Creación',
            accessor: 'Fecha_Creación'
        }, {
            Header: 'Candidatos',
            accessor: 'Candidatos_Activos',
            Cell: e => <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around', alignItems:'center'}}>{e.original.Candidatos_Activos.activos}<div><Button style={{ backgroundColor: e.original.Candidatos_Activos.showCandidates ? "grey" : "#144681", color:"white" }} onClick={() => this.toggleShowCandidates(e.original.Candidatos_Activos.vacanteID, e.original.Candidatos_Activos.index)}>{e.original.Candidatos_Activos.showCandidates ? "Esconder" : "Mostrar"}</Button></div></div>
        }, {
            Header: 'Activado',
            accessor: 'activado'
        }, {
            Header: 'Editar',
            accessor: 'Editar'
        }, {
            Header: 'Borrar',
            accessor: 'Borrar'
        }, {
            Header: 'Bajar Excel',
            accessor: 'Bajar'
        }]

        return (
            <div id="admin" >

                <TopBar />

                <section id="page-title">
                    <div class="row">
                        <div class="columns medium-12  ">
                            <h1>Vacantes</h1>
                        </div>
                    </div>
                </section>
                <section id="admin-feed" class="">
                    <div class="row align-center align-middle ">
                        <div class="columns medium-12  ">
                            <ReactTable
                                data={data}
                                columns={columns}
                                defaultPageSize={12}
                                pageSizeOptions={[12, 16]}
                            />
                        </div>
                    </div>
                </section>
                <div class="row align-right">
                    <div class="columns shrink">
                        <Button style={{ backgroundColor: "#144681", color:"white" }} onClick={() => this.props.history.push({ pathname: '/nueva-vacante' })}  class="button">
                            Agregar nueva vacante
                        </Button>
                    </div>
                </div>

            </div>
        );
    }
}

export default Vacantes;