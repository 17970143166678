import React from 'react';
import Button from 'material-ui/Button';

import moment from 'moment';
import 'moment/locale/es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faCalendarAlt, faClock } from '@fortawesome/free-solid-svg-icons'
import { ClockIcon } from 'react-line-awesome'
import { CalendarIcon } from 'react-line-awesome'

const Phase1 = (props) => {
    return (

        <div className="dashboard-event">
            {(props.dueDate - (props.mountedTimestamp + props.elapsedTime) < 0) && 
                <div className="card dashboard-status">

                    <div className="row align-center ">
                        
                            <div className="columns  text-center align-center ">
                            
                            
                                <i class="las la-hourglass-end"></i>
                                <div className="fs-6">
                                    Límite de tiempo alcanzado
                                </div>
                                <div className="status-label">
                                    No comenzaste la prueba a tiempo
                                </div>
                            </div>
                        
                    </div>
                    
                </div>
            }
            {!(props.dueDate - (props.mountedTimestamp + props.elapsedTime) < 0) && 
                <div className="card dashboard-status">

                    <div className="row align-center ">
                       
                        <div className="columns  text-center align-center ">
                            <i class="las la-calendar"></i>
                            <div className="status-data  fs-6">
                                {moment(props.dueDate, "x").locale('es').format("DD MMM ")} 
                                <span> - </span>
                                {moment(props.dueDate, "x").locale('es').format("hh:mm a")}
                            </div>
                            <div className="status-label">
                                Fecha límite de inicio
                            </div>
                        </div>
                        

                        
                        <div className="columns  text-center align-center ">
                            <i class="las la-clock"></i>
                            <div className="status-data  fs-6">
                            {props.hoursToCompleteTest.toFixed(1).split('.')[1] === '0' ? props.hoursToCompleteTest.toFixed(1).split('.')[0] : props.hoursToCompleteTest.toFixed(1)}h
                            </div>
                            <div className="status-label">
                                Para subir resultados
                            </div>
                        </div>
                        

                        
                    </div>
                   
                </div>
            }
            <div className="card">
                <div className="test">

                        <div className="dashboard-event__date">{props.phase1TimeFormatted}</div>
                        <div className="dashboard-event__title ">Prueba en casa</div>

                        <div className="dashboard-event__content">
                            <div dangerouslySetInnerHTML={{ __html: props.description }} />
                            
                            <p className="fs-7 fw-6 bt-gray">Importante</p>
                            <p className="">
                                <span>Una vez iniciada la prueba tienes </span> 
                                {props.hoursToCompleteTest.toFixed(1).split('.')[1] === '0' ? props.hoursToCompleteTest.toFixed(1).split('.')[0] : props.hoursToCompleteTest.toFixed(1)}h para subir tus resultados
                            </p>
                            <p className="bb-gray ">
                                <span>Tienes como límite iniciar esta prueba el </span>
                                {moment(props.dueDate, "x").locale('es').format("DD MMM ")} 
                                <span> a las </span>
                                {moment(props.dueDate, "x").locale('es').format("hh:mm a")}. 
                                <span> De lo contrario ya no podrás descargar los archivos ni continuar en el proceso de selección.</span>
                            </p>
                            <Button className=" expanded button button--shikoba button--text-thick button--border-medium button--text-upper button--size-l"  href={props.dueDate - (props.mountedTimestamp + props.elapsedTime) < 0 ? "" : props.challenge} target="_blank" disabled={props.dueDate - (props.mountedTimestamp + props.elapsedTime) < 0}
                                onClick={() => props.openBeginHomeworkModal()}>
                                    <FontAwesomeIcon className="button__icon icon" icon={faDownload} />

                                 <span>
                                    Iniciar prueba & descargar archivos
                                 </span>
                            </Button>

                        </div>

                    
                </div>
            </div>
            
        </div>
    );
};
export default Phase1;